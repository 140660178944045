import './article.scss'
import lozad from 'lozad'

document.addEventListener('turbolinks:load', function(){

  //lozad for lazy loading images
  lozad('.lozad', {
    load: function(el) {
      el.src = el.dataset.src
      el.onload = function() {
        el.classList.add('fade-photo')
      }
    }
  }).observe()

  
  const submit_btn = document.querySelector(".article--submit input[type='submit']")
  if(submit_btn == null) { return }

  submit_btn.addEventListener('click', function(e){
    e.target.disabled = true
    e.target.insertAdjacentHTML('afterend', '<div class="field-group__submit__spin"></div>')
    document.getElementById("form-article").submit()
  })


  function showPhotoPreview(e) {
    var photo_selected = this.previousElementSibling
    var photo_preview = document.createElement("img");

    photo_selected.removeAttribute("style")
    photo_selected.style.backgroundImage = `url(${window.URL.createObjectURL(event.target.files[0])})`
    photo_preview.onload = function(){
      window.URL.revokeObjectURL(this.src);
    }
  }
 
});
