import "./hontynoo.scss"

document.addEventListener('turbolinks:load', function(){
  const hontynooToggle = document.querySelector('.hontynoo__toggle--btn')
  
  if(hontynooToggle != null) {
    const overlay = document.querySelector('.sidemenu--overlay')
    const hontynoo = document.querySelector('[data-controller="hontynoo"]')
    hontynoo.style.transform = `translateY(${hontynoo.clientHeight}px)`
    hontynoo.style.visibility = `hidden`

    function toggleHontynooPanel() {
      hontynoo.style = ''
      hontynoo.style.visibility = 'visible'
      hontynoo.classList.toggle('toggle')
      overlay.classList.toggle('show') 

      const togglePlacementHeight = hontynoo.clientHeight - 19;
      if(hontynoo.classList.contains('toggle')) {
        hontynooToggle.parentElement.style.bottom = `${togglePlacementHeight}px`
      } else {
        hontynoo.style.transform = `translateY(${hontynoo.clientHeight}px)`
        hontynoo.style.paddingTop = `0px`
        hontynoo.style.visibility = 'hidden'
        hontynooToggle.parentElement.style = ""
      }
    }

    hontynooToggle.addEventListener('click', (e) => {
      toggleHontynooPanel()
    })

    document.addEventListener('click', (e) => {
      let hontynooVisibility = window.getComputedStyle(hontynoo).visibility

      if(!hontynoo.contains(e.target) && !hontynooToggle.contains(e.target) && (hontynooVisibility != 'hidden')) {
        toggleHontynooPanel()
      }
    })
  }
})
