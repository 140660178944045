import { Controller } from "stimulus"
var moment = require('moment-timezone')

export default class extends Controller {
  static targets = ["question", "explain", "chart", "result", "positionRasi"]

  initialize() {
    //console.log("hontynoo controller initialize!")
    var tz = moment.tz.guess()
    const local_time = moment().tz(tz).unix()
    this.load(local_time, tz)
  }

  connect() {
  }

  disconnect() {
    this.data.set("initialize", false)
    //console.log(`DISCONNECT inistialize : ${this.data.get("initialize")}`) 
  }

  load(local_time, timezone) {
    const lang = this.data.get('lang')
    const url = `/hontynoo?local_time=${local_time}&zone=${timezone}&lang=${lang}`
    const userEmail = document.querySelector("meta[name='user-email']").content
    const userToken = document.querySelector("meta[name='user-token']").content
    fetch(url, {
      method: 'GET',
      headers: {
        "X-User-Email": userEmail,
        "X-User-Token": userToken,
      } 
    })
      .then(response => response.json())
      .then(data => {
        const explainElement = this.explainTarget
        this.questionTarget.innerHTML = data.question
        this.showHorochart(data)

        switch(lang) {
          case 'en':
            explainElement.children[0].innerHTML = data.explain.period_en
            explainElement.children[2].innerHTML = data.explain.meaning_en
            break;
          default:
            explainElement.children[0].innerHTML = data.explain.period
            explainElement.children[1].innerHTML = data.explain.poem
            explainElement.children[2].innerHTML = data.explain.meaning
        }
      })
  }

  showHorochart(data) {
    let rasi_dom = ''
    const rasi = data.rasi
    const forecast_index = data.forecast_index

    Object.keys(rasi).forEach((position) => {
      rasi_dom += `<div class="rasi rasi-hontynoo rasi-hontynoo--${position}">`
      rasi[position].forEach((star) => {
        if(star == 'ล') {
          rasi_dom += `<span class="rasi-hontynoo__lukhana">${star}</span>`
        } else {
          rasi_dom += `<span>${star}</span>`
        }
      })
      rasi_dom += '</div>'
    })
    rasi_dom += `<div class="rasi rasi-hontynoo rasi-hontynoo--${forecast_index} rasi-forecast--${forecast_index}"><span class="rasi-forecast--point">*</span></div>`
    this.positionRasiTarget.innerHTML = rasi_dom 
  }

  viewExplain() {
    const explainBox = this.explainTarget
    const questionBox = this.questionTarget
    const chartBox = this.chartTarget
    const resultBox = this.resultTarget
    const toggle = document.querySelector('.hontynoo__toggle')

    // load React component of hontynoo chart
    explainBox.classList.toggle("hontynoo--explain__show")
    chartBox.classList.toggle("hontynoo--explain__show")

    document.querySelector('.hontynoo--question').classList.toggle("hontynoo--question__explain")

    const questionBoxHeight = questionBox.clientHeight + 40
    const explainBoxHeight = explainBox.scrollHeight + 16
    //const resultBoxHeight = resultBox.clientHeight
    const iconHalfHeight = 19
    const chartHeight = 168 + 16;

    if(explainBox.classList.contains("hontynoo--explain__show")) {
      //let hi = explainBoxHeight + questionBoxHeight + iconHalfHeight + chartHeight
      let hi = questionBoxHeight + resultBox.scrollHeight + iconHalfHeight + 24
      toggle.style.bottom = `${hi}px`
    } else {
      let hi = questionBoxHeight + iconHalfHeight
      toggle.style.bottom = `${hi}px` 
    }
  }

}

