import React from 'react'

class BirthOrigin extends React.Component {
  render() {
    var displayBirthOrigin = {
      display: this.props.birthVisible ? 'block' : 'none'
    }

    let description = `${this.props.birth_desc.desc} ${this.props.birth_desc.thai_desc} ${this.props.birth_desc.age}`
    return(
      <div className="horo-birth">
        {description}
      </div>
    )
  }
}

export default BirthOrigin;
