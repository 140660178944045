import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['packages', 'proceeding', 'submit']

  //initialize() {
    //console.log("initial augury topup controller")
  //}

  connect() {
    const packageDisable = this.data.get('proceeding')
    if(packageDisable == 'true') {
      this.disableSubmit()
      this.showProceedingStatus()
    } else {
      this.enableSubmit()
    }
  }

  disconnect() {
    this.proceedingTarget.innerText = ""
  }

  disableSubmit() { this.submitTarget.classList.add('topup--disabled') }
  enableSubmit() { this.submitTarget.classList.remove('topup--disabled') }
  toggle() {
    console.log(this.context.controller.element)
    this.context.controller.element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    this.packagesTarget.classList.toggle("topup--packages__hide")
  }

  flashMsg(month) {
    return `
      <div class="alert alert-warning alert-dismissible fade show" role="alert">
        <div class="flash flash-notice">
          <p>ส่งคำสั่งเพิ่มเครดิตจำนวน ${month} เดือน เรียบร้อย</p>
          <p>กรุณาแจ้งเราหลังจากชำระเงิน เพื่อทำการอัพเดตเครดิตให้ต่อไปครับ</p>
        </div>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" onclick="closeFlashAlert(this)" title="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `
  }

  showProceedingStatus(month) {
    const last_topup_date = this.proceedingTarget.dataset.proceedingDate
    const topup_amount = this.data.get('proceedAmount')
    let body = `
      <p>คุณได้ทำรายการเติมเครดิตจำนวน ${topup_amount} เดือน</p>
      <p>ไว้เมื่อ วันที่ ${last_topup_date}</p>
      <p>คุณสามารถชำระเงินได้ทาง <a href="/ช่องทางชำระเงิน">ช่องทางการชำระเงินของ horaram</a> ครับ</p>
    `
    this.proceedingTarget.insertAdjacentHTML('afterbegin', body)
  }

  choose(element) {
    if(this.data.get('proceeding') == 'true') { return }

    const selectedMonth = element.target.dataset.period
    console.log(element.target)

    let formData = new FormData
    formData.append('topup[month]', selectedMonth)

    const url = '/topups'
    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      let flashWrap = document.querySelector('.group-content.wrap-flash') 
      flashWrap.insertAdjacentHTML('afterbegin', this.flashMsg(data.requested_month))

      // close topup-packages
      this.packagesTarget.classList.add("topup--packages__hide")
      this.disableSubmit()
      this.data.set('proceedAmount', data.requested_month)
      this.proceedingTarget.dataset.proceedingDate = new Date().toISOString().slice(0,10)
      this.showProceedingStatus()
    })
  }
}
