import moment from 'moment'
import React from 'react'
import { draw_chart, draw_astro } from '../chart/horotoday_chart.js'
import ChartBase from './ChartBase'

var horo_circle = require('../chart/horo_circle_1.svg')


class RasiElement extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return(
      <div key={`rasi-${this.props.rasi}`} className={`rasi rasi-${this.props.rasi}${this.props.rasiTravel ? (" rasi-travel-" + this.props.rasi) : ""}`} >
      { this.props.values.map((v) => {
          return <span key={v}>{v}</span>
        }) 
      }
      </div>
    )
  }
} //RasiElement


class AstroToday extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      astros: []
    }
  }

  componentWillMount() {
    this.setState({
      astros: this.props.astros
    })
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.astros != this.props.astros) {
      this.setState({ astros: nextProps.astros })
    }
  }

  rasisData(astros) {
    let rasis = astros.map(astro => astro.rasi)
    let data = {}
    rasis.forEach((r) => {
      if(r[1] == "ลั") { return; }
      (data[r[0]] == undefined) ? data[r[0]] = [r[1]]
                                      : data[r[0]].push(r[1])  
    })
    return data
  }

  render() {
    const data_origin = this.rasisData(this.props.astros)

    return(
      <div className="horotoday--astro-chart">
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 300">
          {/* <use xlinkHref={`${horo_circle}#chartbase`} x="0" y="0" width="300" height="300"></use> */}

            {/* <ChartBase astros={this.state.astros} /> */}
            <ChartBase />
						<g ref={"canvas"} key={Date.now()} id="canvas">
          </g>
        </svg>

        <div className="position-rasi">
          { Object.keys(data_origin).map((k) => {
              return <RasiElement key={`rasi-origin-${k}`} rasi={k} values={data_origin[k]} rasiTravel={false} />
             })
          }
        </div>
      </div>
    )
  }
}

export default AstroToday
