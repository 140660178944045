import React from 'react'

class Dithi extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { dithi_origin, dithi_travel } = this.props

    //return(
    //  <div className="horo-dithi">
    //    { (dithi_origin != "") ? <div className="dithi-origin"><span className="dithi-label">ดิถีดวงกำเนิด:</span><span className={ `dithi-value ${this.props.accessLevel == 'master' ? '' : 'dithi-blur'}` }>{ this.props.accessLevel == 'master' ? dithi_origin : dithi_origin.split('').sort(function(){return 0.5-Math.random()}).join('')}</span></div> : <div></div> }
    //    { dithi_travel != "" ? <div className="dithi-travel"><span className="dithi-label">ดิถีดวงจร:</span> {dithi_travel}</div> : <div></div> }
    //  </div>
    //)

    return(
      <div className="horo-dithi">
        { (dithi_origin != "") ? <div className="dithi-origin"><span className="dithi-label">ดิถีดวงกำเนิด:</span><span className={ `dithi-value` }>{ dithi_origin }</span></div> : <div></div> }
        { dithi_travel != "" ? <div className="dithi-travel"><span className="dithi-label">ดิถีดวงจร:</span> {dithi_travel}</div> : <div></div> }
      </div>
    )
  }
}

export default Dithi;
