import Rails from '@rails/ujs'
import moment from 'moment'
import React from 'react'
import BirthFormProfile from './BirthFormProfile'

class FormProfile extends React.Component {
  constructor(props) {
    super(props)

    //this.hiddenBirthdate = React.createRef()
    const profile_data = this.props.profile_data
    const [ id, name, birthdate, gender ] = Object.keys(profile_data).map( d => {
      return profile_data[d] == null ? '' : profile_data[d]
    })

    this.state = {
      id: id,
      name: name,
      gender: gender,
      birth: birthdate
    }
  }

  handleSubmit(event) {
    //event.preventDefault()
    //console.log(event.target)
  }

  setName(e) { this.setState({name: e.target.value}) }
  setGender(e) { this.setState({gender: e.target.value}) }

  setBirthDate(birthdate) {
    this.setState({birth: birthdate})
  }

  render() {
    const profile_id = this.state.id
    const update_profile_path = `/profiles/${profile_id}`

    return(
      <div className="form--profile--wrapper">
        <form className="form form--profile" action={ update_profile_path } method="POST" acceptCharset="UTF-8" data-remote="true" onSubmit={ e => this.handleSubmit(e) }>
          <input type="hidden" name="_method" value="PUT" />
          <input type="hidden" name="utf8" value="true" />
          <input type="hidden" name="authenticity_token" value={ Rails.csrfToken() } />
          
          <div className="form--group">
            <label htmlFor="profile_name" className="label__inline label__inline__profile">ชื่อ</label>
            <input type="text" id="profile_name" name="profile[name]" value={this.state.name} onChange={ e => this.setName(e) } required autoFocus/>
          </div>
          <div className="form--group">
            <label htmlFor="profile_gender" className="label__inline label__inline__profile">เพศ</label>
            <select id="profile_gender" name="profile[gender]" value={this.state.gender} onChange={ e => this.setGender(e) } required>
              <option value="" disabled>เลือกเพศ</option>
              <option value="Female">หญิง</option>
              <option value="Male">ชาย</option>
            </select>
            <input type="hidden" name="profile[birthdate]" value={this.state.birth} />
          </div>
          <div className="form--group">
            <BirthFormProfile initBirth={this.state.birth} setBirthDate={ e => this.setBirthDate(e) } />
          </div>

          <div className="form--group">
            <div className="note">โปรดระบุ วันเดือนปี เวลาเกิด และเพศ เพื่อให้ท่านได้รับคำพยากรณ์ที่ตรงกับตัวท่านมากที่สุด</div>
          </div>

          <div className="form--group">
            <button id="submit-btn" role="button" type="submit" className="btn btn--submit">Submit</button>
          </div>
        </form>
      </div>
    )
  }
}

export default FormProfile;
