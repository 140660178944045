import Rails from '@rails/ujs'

document.addEventListener('turbolinks:load', function(){
  // add Model for new user input in advance
  document.body.insertAdjacentHTML('beforeend', modalBody())

  function modalBody(){
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return `
      <div class="modal fade fate--modal" id="saveCustomerModal" tabindex="-1" role="dialog" aria-labelledby="saveCustomerModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">ข้อมูลเจ้าชาตา</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="wrap-avatar fate-avatar--preview">
          <img id="fate_avatar_preview" alt="" />
        </div>

        <div class="modal-body--item field-choose-photo">
          <div class="btn-avatar-upload--wrapper">
            <div class="btn-avatar-upload">
              <i class="fas fa-image"></i>
              <span>เปลี่ยนรูป</span>
            </div>
            <input id="fate-avatar-input" class="btn-avatar-upload--inputfile" type="file" name="avatar[image]" accept="image/*" />
          </div> <!--.btn-avatar-upload -->
        </div>

        <div class="modal-body--item">
          <form id="new_fate" action="/lukhana/fates" accept-charset="UTF-8" method="post">
            <input type="hidden" name="authenticity_token" id="authenticity_token" value="${Rails.csrfToken()}">
            <input type="hidden" name="timezone" id="modal-user-timezone" value="${timezone}">
            <div class="field-item" id="field-avatar-id">
              <input type="hidden" id="fate_avatar_id" name="fate[avatar_id]">
            </div>
            <div class="field-item">
              <input type="text" id="input-fate-name" placeholder="ชื่อ-นามสกุล" name="fate[name]" />
            </div>
            <div class="field-item">
              <input type="datetime-local" name="fate[horoscope_attributes][birth_iso]" id="input-birthdate" >
              <input type="hidden" name="fate[horoscope_attributes][id]" id="fate_horoscope_attributes_id">
            </div>
            <div class="field-item">
              <textarea rows="5" placeholder="รายละเอียด" name="fate[remark]" id="fate_remark"></textarea>
            </div>

            <div class="field-item field-submit">
              <input type="submit" name="commit" value="บันทึก" class="fate--modal--btn fate--modal--btn__submit" data-disable-with="บันทึก">
              <button type="button" class="fate--modal--btn fate--modal--btn__close" data-dismiss="modal" aria-label="Close">Cancel</button>
            </div>
          </form>
        </div> <!--.modal-body-item -->
      </div> <!-- .modal-body -->
    </div> <!--.modal-content -->
  </div>
</div>
    `
  }
})
