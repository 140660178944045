import 'bootstrap/dist/js/bootstrap.bundle'
//import 'bootstrap/dist/css/bootstrap'
import './horoscope/modal_input.js'
import './horoscope/index.js'
import './fate/fate.js'
import './fate/fate_modal.js'

import './lukhana.scss'

document.addEventListener('turbolinks:load', function(){
  const lukhanaHoroscope = document.getElementById('lukhana-horoscope')
  if(lukhanaHoroscope != null) {
    lukhanaHoroscope.classList.add("section__min-height")
  }
})
