import React from 'react'
import { draw_chart } from './models/horo_chart.js'

class ChartBase extends React.Component {
  componentDidMount() {
    const label = !this.props.travel
    draw_chart(this.props.astros, label)
  }
  render() {
    return(
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460 460">
        <g id="chartbase" transform="translate(109,109)">
          <circle className="chartbase--outer" cx="121" cy="121" r="120"/>
          <path fill="none" d="M-332.4 63.6"/><path className="chartbase" d="M142.7 142.7l63 63.4"/><path className="chartbase" d="M98.3 97.8L36.2 35.7"/><path className="chartbase" d="M98.6 2.6v237"/><path className="chartbase" d="M142.6 2.6v236"/><path className="chartbase" d="M3.6 98.6h235"/><path className="chartbase" d="M3.6 142.6h235"/><path className="chartbase" d="M142.7 98.6l63.4-62.9"/><path className="chartbase" d="M98.9 142.2l-62.7 62.9"/>
        </g>
      </svg>
    )
  }
} //ChartBase

export default ChartBase
