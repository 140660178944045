import smileImageUrl from '../../../images/lukhana/smile_small.png'

document.addEventListener('turbolinks:load', function(){
  let fateAvatarImgs = document.querySelectorAll('.fate--avatar img')
  if(fateAvatarImgs.length > 0) {
    let smileImg = new Image()
    smileImg.onload = function() {
      fateAvatarImgs.forEach(img => {
        if(img.src == undefined || img.src == '') {
          img.src = this.src
        }
      })
    }
    smileImg.src = smileImageUrl
  }
})
