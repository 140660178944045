import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []

  initialize() {
    console.log("form profile controller initialize")
  }
}
