
function clearMainBackground() {
  document.querySelector("main").classList.remove("main__lukhana")
  document.getElementById("lukhana-horoscope").classList.add("bg-fill-white")
}

function clearModalData() {
  //console.log('clear modal data from BirthForm')
  let modal = document.getElementById("saveCustomerModal")
  if(modal != null) {
    document.getElementById('input-fate-name').value = ''
    document.getElementById('fate_horoscope_attributes_id').value = ''
    document.getElementById('fate_remark').value = ''
    document.getElementById('fate_avatar_id').value = ''

    var avatarImg = document.getElementById('fate_avatar_preview')
    avatarImg.src = ''

    let put_request = document.querySelector("input[value='PUT']")
    if(put_request != null) { put_request.remove() }
    document.getElementById('new_fate').action = `/lukhana/fates`
  }
}

function removeFateAvatar() {
  //const fateCurrent = document.querySelector(".fate--current")
  const fateCurrent = document.querySelector(".astro--fate__avatar")
  if(fateCurrent != null) {
    fateCurrent.remove()
    window.history.replaceState(null, null, window.location.pathname)
  }
}

export { clearMainBackground, clearModalData, removeFateAvatar }
