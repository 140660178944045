import React from 'react' 
import moment from 'moment'

class TabTravel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      num_day: 1,
      num_month: 0,
      num_year: 0
    }
    this.travelSubmit = this.travelSubmit.bind(this)
    this.openDropdownMenu = this.openDropdownMenu.bind(this)
  }

  componentDidMount() {
    const dropdownMenu = this.ddmenu
    const dropdownToggle = this.ddtoggle

    document.addEventListener('click', function(e){
      let isClickToggleButton = dropdownToggle === e.target
      let isClickInsideMenu = dropdownMenu.contains(e.target)

      if(dropdownMenu.classList.contains('show') && !isClickInsideMenu && !isClickToggleButton) {
        dropdownMenu.classList.remove("show")
      }
    })
  }

  today() { return new Date() }
  tomorrow() {
    let today = this.today()
    return moment(new Date(today.setDate(today.getDate() + 1))).format('YYYY-MM-DDTHH:ss') 
  }
  next_day(day) {
    let today = this.today()
    return moment(new Date(today.setDate(today.getDate() + day))).format('YYYY-MM-DDTHH:ss') 
  }

  openDropdownMenu(e) {
    this.ddmenu.classList.toggle('show')
  }

  clickTravel(num_travel, e) {
    const { num_day, num_month, num_year } = this.state
    switch(e.target.dataset.by) {
      case "day": this.setState({num_day: num_day + num_travel}); break;
      case "month": this.setState({num_month: num_month + num_travel}); break;
      case "year": this.setState({num_year: num_year + num_travel}); break;
    }
  }

  travelSubmit() {
    const { num_day, num_month, num_year } = this.state
    //console.log(`travel date is ${moment().add({days: num_day, months: num_month, years: num_year}).format()}`)

    const travel_date = moment().add({
      days: num_day, months: num_month, years: num_year
    }).format('YYYY-MM-DDTHH:ss')

    this.props.submitTravel(travel_date)
  }

  render() {
    const { num_day, num_month, num_year } = this.state

    return(
      <div className="nav-link" data-toggle="tab" role="tab" aria-controls="table-travel" aria-selected="false">
        <button id="tab-travel" className="btn btn-secondary btn-sm">ดวงจร</button>
        <button ref={(btn) => {this.ddtoggle = btn}} type="button" className="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split" aria-haspopup="true" aria-expanded="false" onClick={this.openDropdownMenu}>
          <span className="sr-only">Toggle Dropdown</span>
        </button>

        <div ref={(element) => {this.ddmenu = element}} className="dropdown-menu">
          <div className="travel-by by-day">
            <a className="btn-rewind travel-by-item" tabIndex="0"><i className="fa fa-chevron-left" data-by="day" onClick={(e) => this.clickTravel(-1, e)}></i></a>
            <div className="label travel-by-item">{num_day} วัน</div>
            <a className="btn-forward travel-by-item" tabIndex="0"><i className="fa fa-chevron-right" data-by="day" onClick={(e) => this.clickTravel(1, e)}></i></a>
          </div>

          <div className="travel-by by-month">
            <a className="btn-rewind travel-by-item" tabIndex="0"><i className="fa fa-chevron-left" data-by="month" onClick={(e) => this.clickTravel(-1, e)}></i></a>
            <div className="label travel-by-item">{num_month} เดือน</div>
            <a className="btn-forward travel-by-item" tabIndex="0"><i className="fa fa-chevron-right" data-by="month" onClick={(e) => this.clickTravel(1, e)}></i></a>
          </div>

          <div className="travel-by by-year">
            <a className="btn-rewind travel-by-item" tabIndex="0"><i className="fa fa-chevron-left" data-by="year" onClick={(e) => this.clickTravel(-1, e)}></i></a>
            <div className="label travel-by-item">{num_year} ปี</div>
            <a className="btn-forward travel-by-item" tabIndex="0"><i className="fa fa-chevron-right" data-by="year" onClick={(e) => this.clickTravel(1, e)}></i></a>
          </div>

          <div className="travel-submit">
            <button className="btn-travel-submit" type="button" onClick={this.travelSubmit}>แสดง</button>
          </div>
        </div> 
      </div>
    )
  }
}

class AstroTabs extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.submitTravel = this.submitTravel.bind(this)
  }
  
  handleClick(e) {
    this.props.getAstros(e.target.id)
  }

  submitTravel(travel_date, e) {
    //console.log(`submit travel date ${travel_date}`)
    this.props.getAstros("tab-travel", travel_date)
  }

  render() {
    let today = new Date()
    let next_2_days = moment(new Date(today.setDate(today.getDate() + 2))).format('YYYY-MM-DDTHH:ss')

    if(this.props.accessLevel == "master") {
      return(
        <ul className="nav nav-tabs" id="tables-tab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id= "tab-origin" data-tab="tab-origin" data-toggle="tab" role="tab" aria-controls="table-origin" aria-selected="true" onClick={this.handleClick}>ดวงกำเนิด</a>
          </li>
          <li className="nav-item">
            <TabTravel submitTravel={this.submitTravel} />
          </li>
          <li className="nav-item">
            <a className="nav-link" id="tab-somput" tabIndex="0" data-tab="tab-somput" data-toggle="tab" role="tab" aria-controls="table-somput" aria-selected="false" onClick={this.handleClick}>สมผุส</a>
          </li>
        </ul>
      )

    } else {
      return(
        <ul className="nav nav-tabs" id="tables-tab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id= "tab-origin" data-tab="tab-origin" data-toggle="tab" role="tab" aria-controls="table-origin" aria-selected="true" onClick={this.handleClick}>ดวงกำเนิด</a>
          </li>
          <li className="nav-item">
            <TabTravel submitTravel={this.submitTravel} />
          </li>
        </ul>
      )
    }
  }
}

export default AstroTabs
