import './about.js';
import './post_preview.js';

import './flash.scss';
import './login.scss';
import './page.scss';
import './post.scss';
import './about.scss';
import './package.scss';

require('particles.js/particles.js') 

document.addEventListener('turbolinks:load', function(){
  let btnLang = document.querySelector(".lang-selected")
  let ul = document.querySelector('ul.langs')

  if(btnLang != null) {
    btnLang.onclick = (e) => {
      //let ul = e.target.closest(".langs")
      let overflowValue = window.getComputedStyle(ul).overflow
      if(overflowValue === 'hidden') {
        ul.style.overflow = 'visible'
      } else {
        ul.style.overflow = 'hidden'
      }
    } 
  }

  // Detect click outside of lang toggle button
  document.addEventListener('click', (e) => {
    if(!ul.contains(e.target)) {
      ul.style.overflow = 'hidden'
    }
  })


  const firstHighlight = document.getElementById("first-highlight")
  if(firstHighlight == null) { return }

  particlesJS('first-highlight', {
      "particles": {
      "number": {
        "value": 180,
        "density": {
          "enable": false,
          "value_area": 800
        }
      },
      "color": {
        "value": "#ffffff"
      },
      "shape": {
        "type": "circle",
        "stroke": {
          "width": 0,
          "color": "#000000"
        },
        "polygon": {
          "nb_sides": 5
        },
        "image": {
          "src": "img/github.svg",
          "width": 100,
          "height": 100
        }
      },
      "opacity": {
        "value": 0.33,
        "random": true,
        "anim": {
          "enable": false,
          "speed": 1,
          "opacity_min": 0.1,
          "sync": false
        }
      },
      "size": {
        "value": 2,
        "random": true,
        "anim": {
          "enable": false,
          "speed": 40,
          "size_min": 0.1,
          "sync": false
        }
      },
      "line_linked": {
        "enable": false,
        "distance": 150,
        "color": "#ffffff",
        "opacity": 0.4,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 0.3,
        "direction": "right",
        "random": false,
        "straight": false,
        "out_mode": "out",
        "bounce": false,
        "attract": {
          "enable": false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": false,
          "mode": "repulse"
        },
        "onclick": {
          "enable": false,
          "mode": "push"
        },
        "resize": true
      },
      "modes": {
        "grab": {
          "distance": 400,
          "line_linked": {
            "opacity": 1
          }
        },
        "bubble": {
          "distance": 400,
          "size": 40,
          "duration": 2,
          "opacity": 8,
          "speed": 3
        },
        "repulse": {
          "distance": 200,
          "duration": 0.4
        },
        "push": {
          "particles_nb": 4
        },
        "remove": {
          "particles_nb": 2
        }
      }
    },
    "retina_detect": true
  });

})

window.closeFlashAlert = function(element) {
  element.parentElement.remove()
}
