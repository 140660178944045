import Rails from '@rails/ujs'
import './fate.scss'

document.addEventListener('turbolinks:load', function(){
  let inputImgBtn = document.getElementById("fate-avatar-input")
  if(inputImgBtn != null) {
    inputImgBtn.addEventListener('change', function(e){
      uploadAvatar(event.target.files[0])
      //previewPhoto(this) 
    })
  }
})

function uploadAvatar(selected_file) {
  let form_data = new FormData()
  let image = new Image()
  image.onload = function (imageEvent) {
    let canvas = document.createElement('canvas'),
      max_size = 420,
      width = image.width,
      height = image.height

    if(width > height) {
      if (width > max_size) {
          height *= max_size / width;
          width = max_size;
      }
    } else {
      if (height > max_size) {
          width *= max_size / height;
          height = max_size;
      }
    }
    canvas.width = width
    canvas.height = height
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    var dataUrl = canvas.toDataURL('image/jpeg');
    var resizedImage = dataURLToBlob(dataUrl);

    // send the resized image to server
    form_data.append("avatar[image]", resizedImage, 'resized_photo.jpg')

    fetch('/lukhana/fates/avatar/upload', {
      method: 'POST',
      body: form_data,
      headers: {
        "X-User-Email": document.querySelector("meta[name='user-email']").content,
        "X-User-Token": document.querySelector("meta[name='astro-token']").content,
        "X-CSRF-Token": Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      let field_avatar_id = document.getElementById('field-avatar-id')
      field_avatar_id.innerHTML = `<input type="hidden" name="fate[avatar_id]" value="${data.avatar_id}">`
    })

    URL.revokeObjectURL(objectURL) 
  } // image.onload

  var objectURL = URL.createObjectURL(selected_file)
  image.src = objectURL

  let avatarPreview = document.querySelector(".fate-avatar--preview img")
  avatarPreview.src = objectURL
}


/* Utility function to convert a canvas to a BLOB */
var dataURLToBlob = function(dataURL) {
  var BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = parts[1];

      return new Blob([raw], {type: contentType});
  }

  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(':')[1];

  var raw = window.atob(parts[1]);
  var rawLength = raw.length;

  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], {type: contentType});
}

window.cancelForm = function(){
  console.log("cancel form")
  event.preventDefault()
  $("#saveCustomerModal").modal('hide')
}

//document.addEventListener('turbolinks:before-cache', function(){
//  var customerModal = $("#saveCustomerModal")
//  customerModal.find(".modal-body").html("")
//})
