import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks/dist/turbolinks";

import 'bootstrap/dist/css/bootstrap'
import "styles/base.scss";

import "components/page/page";
import "components/sidemenu/sidemenu";
import "components/article/article";
import "components/lukhana/lukhana";
import "components/hontynoo/hontynoo";
import "components/augury/augury";
import "components/horotoday/horotoday";
import "components/profile/profile";
import "components/notification/notification";
import "components/package/package";


require.context('../images/', true, /\.(gif|jpg|png|svg)$/i)

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

Turbolinks.start();
// rails-ujs to makge use unobtrusive javascript like method :delete
Rails.start();
