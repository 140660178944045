import moment from 'moment'
import React from 'react'
import BirthOrigin from './BirthOrigin';
import AstroChart from './AstroChart';
import Dithi from './Dithi';
import MoonPhase from './MoonPhase';
import AstroTable from './AstroTable';
import AstroTabs from './AstroTab';

class AstroPreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      travel: false,
      astros: [],
      astros_travel: [],
      astros_somput: [],
      birth_origin: "",
      birth_travel: "",
      birth_desc: "",
      dithi_origin: "",
      dithi_travel: "",
      moonphase: "",
      tabStatus: "tab-origin",
      displayChart: { origin: true, travel: false, somput: false },
    }
    this.userEmail = document.querySelector("meta[name='user-email']").content
    this.userToken = document.querySelector("meta[name='astro-token']").content
    this.keyCount = 0
    this.getKey = this.getKey.bind(this)
    this.setTabStatus = this.setTabStatus.bind(this)
    this.getAstros = this.getAstros.bind(this)
    this.getAstroTravel = this.getAstroTravel.bind(this)
  }

  componentWillMount() {
    this.setState({
      birth_origin: this.props.birth
    })
  }

  // TODO Need to verify if ComponentWillReceiveProps was really get called ????
  componentWillReceiveProps(nextProps){
    if(this.props.birth_origin !== nextProps.birth) {
      let url = `/lukhana/horoscopes/astro?birth=${nextProps.birth}&zone=${this.props.timezone}`
      fetch(url,
        { 
          method: 'GET',
          headers: {
            "X-User-Email": this.userEmail,
            "X-User-Token": this.userToken
          }
        }
      )
        .then(response => response.json())
        .then(horo => {
          let astros = horo.astros
          let birth_desc = { ...horo.birth }
          let dithi = horo.dithi.join(", ")
          let moonphase = { ...horo.moonphase }

          this.setState({
            astros: astros,
            birth_origin: nextProps.birth,
            birth_travel: "",
            birth_desc: birth_desc,
            dithi_origin: dithi,
            dithi_travel: "",
            moonphase: moonphase,
            travel: false,
            tabStatus: "tab-origin",
            displayChart: {origin: true, travel: false, somput: false}
          })
        })
        .catch(error => console.log('error! ', error))
    }
  }

  //componentWillUpdate(nextProps, nextState) {
  //  Note that you cannot call this.setState() here; nor should you do anything else (e.g. dispatch a Redux action) that would trigger an update to a React component before componentWillUpdate() returns.

  //If you need to update state in response to props changes, use componentWillReceiveProps() instead.
  //}

  getKey(){
    return this.keyCount++;
  }

  getAstroTravel(d) {
    let birth_timestamp = moment(d).tz(this.props.timezone).unix()
    let url = `/lukhana/horoscopes/astro_travel?birth=${birth_timestamp}&zone=${this.props.timezone}`
    fetch(url,
      { 
        method: 'GET',
        headers: {
          "X-User-Email": this.userEmail,
          "X-User-Token": this.userToken
        }
      }
    )
      .then(response => response.json())
      .then(horo => {
        this.setState({
          astros_travel: horo.astros_travel,
          travel: true,
          birth_travel: d,
          dithi_travel: horo.dithi.join(", "),
          moonphase: horo.moonphase
        })
      })
      .catch(error => console.log('error! ', error))
  }

  getAstroSomput() {
    let url = `/lukhana/horoscopes/astro_somput?birth=${this.state.birth_origin}&birth_stamp=${this.state.birth_stamp}&zone=${this.props.timezone}`
    fetch(url, 
      { 
        method: 'GET',
        headers: {
          "X-User-Email": this.userEmail,
          "X-User-Token": this.userToken
        }
      } 
    )
      .then(response => response.json())
      .then(horo => {
        this.setState({
          astros_somput: horo.astros_somput,
          birth_travel: "",
          travel: false,
          dithi: "",
          moonphase: horo.moonphase
        })
      })
      .catch(error => console.log('error! ', error))
  }

  defaultBirthdate() {
    let today = new Date()
    let d = moment(new Date(today.setDate(today.getDate()))).format('YYYY-MM-DDTHH:ss')
    return d 
  }
  getAstros(tabId, birthdate=this.defaultBirthdate()) {
    this.setTabStatus(tabId)
    switch(tabId) {
      case "tab-travel":
        this.getAstroTravel(birthdate)
        break

      case "tab-somput":
        this.getAstroSomput()
        break
    } 
  }

  setTabStatus(tabId) {
    let t
    switch(tabId) { 
      case "tab-origin":
        this.setState({displayChart: {origin: true, travel: false, somput: false}, birth_travel: "", dithi_travel: ""})
        break
      case "tab-travel":
        t = true
        this.setState({displayChart: {origin: false, travel: true, somput: false}})
        break
      case "tab-somput":
        this.setState({displayChart: {origin: false, travel: false, somput: true}})
        break

      default:
        t = false
    }
    this.setState({tabStatus: tabId, travel: t})
  }

  render() {
    if(this.state.astros.length > 0) {
      //console.log("render from AstroPreview")
      let key_chart = `chart-${Date.now()}`
      let key_table = `table-${Date.now()}`

      return(
        <div className="astro-preview">
          <BirthOrigin birth_desc={this.state.birth_desc} /> 

          <AstroChart key={key_chart} travel={this.state.travel} astros={this.state.astros} astros_travel={this.state.astros_travel} astros_somput={this.state.astros_somput} displayChart={this.state.displayChart} birth_travel={this.state.birth_travel} />
          <Dithi dithi_origin={this.state.dithi_origin} dithi_travel={this.state.dithi_travel} accessLevel={this.props.accessLevel} />
          <MoonPhase phase={this.state.moonphase} accessLevel={this.props.accessLevel} />

          <div className="astro-table">
            <div className="wrap-nav-tab">
              <AstroTabs setTabStatus={this.setTabStatus} getAstros={this.getAstros} getAstroTravel={this.getAstroTravel} accessLevel={this.props.accessLevel}/>
            </div> {/*<!--.wrap-nav-tab -->*/} 
 
            <AstroTable key={this.getKey()} name="origin" show={this.state.tabStatus == "tab-origin" ? true : false} astros={this.state.astros} /> 
            <AstroTable key={this.getKey()} name="travel" show={this.state.tabStatus == "tab-travel" ? true : false} astros={this.state.astros_travel} /> 
            { 
              this.props.accessLevel == 'master' ?
                <AstroTable key={this.getKey()} name="somput" show={this.state.tabStatus == "tab-somput" ? true : false} astros={this.state.astros_somput} />
                : ""
            }
          </div>
        </div>
      )

    } else {
      return (
        <div className="astro-preview">
        </div>
      )
    }
  }
}

export default AstroPreview
