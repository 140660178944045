//import moment from 'moment'
var moment = require('moment-timezone')
import React from 'react';
import ReactDOM from 'react-dom';

import BirthForm from './BirthForm';
import AstroPreview from './AstroPreview';
import AstroFate from './AstroFate';
import avatarSmileUrl from '../../../images/lukhana/smile_small.png'
import { clearMainBackground } from '../helper/helper'

class Horoscope extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      astros: [],
      visible: false,
      birthDescription: "วัน เวลา เกิด",
      drawChart: false,
      birth: this.props.initBirth
    }
  }

  componentDidMount() {
    let formatDate = 'YYYY-MM-DD HH:mm'
    let present = moment().format(formatDate)
    let initBirth = moment.unix(this.props.initBirth).format(formatDate)

    if(present !== initBirth) {
      this.hideFormHoro()
      this.setState({drawChart: true})
    }
  }
  componentWillUnmount() {
    //alert("Unmount after render Horoscope")
  }

  setBirthDate(birth) {
    this.setState({
      birth: birth,
      drawChart: true
    })
  }

  hideFormHoro() {
    const horoscope = document.querySelector('#horoscope')
    const btnFormToggle = document.querySelector(".btn-form-horo--toggle")
    horoscope.classList.toggle("move-up")
    btnFormToggle.classList.toggle("rotate-down")
  }

  render() {
    return (
      <div className="wrapper-horoscope">
        <BirthForm initBirth={this.state.birth} timezone={this.props.timezone} setBirthDate={(e) => this.setBirthDate(e)} />
        <AstroFate fate={this.props.fate} timezone={this.props.timezone} drawChart={this.state.drawChart} />
        <AstroPreview birth={this.state.birth} timezone={this.props.timezone} accessLevel={this.props.accessLevel} />
      </div>
    )
  }
}

document.addEventListener('turbolinks:load', function(e) {
  //console.log('horoscope..index.js is called')
  const horoscope = document.querySelector("#horoscope");
  if(horoscope == null) { return }

  const memberType = horoscope.dataset.member
  let init_birth = moment(new Date()).format('YYYY-MM-DDTHH:mm')
  let fate = {}

  horoscope.insertAdjacentHTML('afterbegin', '<div class="spinner__vowvow"></div>')
  
  // show smile avatar as default Fate photo
  //renderSmileAvatar()

  if(window.location.search != "") {
    // clear main background color
    clearMainBackground()
    let birth = window.location.search.match(/\d{4}-\d{2}-\d{2}T.+$/)[0].replace(/\%3A/g,":")
    const cust = moment(birth)
    const day = cust.date()
    const month = cust.month()
    const year = cust.year()
    const hour = cust.hour().toString().length === 2 ? cust.hour() : `0${cust.hour()}`
    const min = cust.minute()
    const month_list = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",	"พฤษภาคม", "มิถุนายน", "	กรกฎาคม", "	สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]

    init_birth = moment(birth).format('YYYY-MM-DDTHH:mm')

    // Request horoscope info for this record
    requestHoroscopeInfo(init_birth)

    // initialize fate onject
    let fate_data = document.getElementById("fate_data").dataset
    fate.name = fate_data.fateName
    fate.avatar_url = fate_data.fateAvatarUrl
  }

  const tz = moment.tz.guess()
  const init_birth_stamp = moment(init_birth).tz(tz).unix()

  ReactDOM.unmountComponentAtNode(horoscope)
  ReactDOM.render(<Horoscope initBirth={init_birth_stamp} timezone={tz} accessLevel={memberType} fate={fate} />, horoscope)


  const btnFormToggle = document.querySelector(".btn-form-horo--toggle")
  btnFormToggle.addEventListener('click', function(e) {
    e.preventDefault()
    //console.log("will translate form up")
    horoscope.classList.toggle("move-up")
    this.classList.toggle("rotate-down")
  })


  //enable cutomer edit icon
  //const customerEditBtn = document.querySelector("[data-target='#saveCustomerModal']") 
  //customerEditBtn.classList.remove("disable")

  const btnClearInfo = document.querySelector(".btn-clear-info")
  if(btnClearInfo != null) {
    btnClearInfo.addEventListener('click', function(e){
      document.querySelectorAll(".form-new-user input[type='text']").forEach((x) => { x.value = "" })
      document.querySelectorAll(".form-new-user input[type='password']").forEach((x) => { x.value = "" })
    })
  }


  function renderSmileAvatar() {
    let avatarPreview = document.getElementById('fate_avatar_preview')
    let avatarSmile = new Image()
    avatarSmile.onload = function(){
      avatarPreview.src = this.src
    }
    avatarSmile.src = avatarSmileUrl
  }

  function requestHoroscopeInfo(birth) {
    let url = '/lukhana/fates/modal.json?birth_iso=' + birth + '&zone=' + Intl.DateTimeFormat().resolvedOptions().timeZone

    fetch(url, {
      method: 'GET',
      headers: {
        "X-User-Email": document.querySelector("meta[name='user-email']").content,
        "X-User-Token": document.querySelector("meta[name='astro-token']").content
      }
    })
    .then(response => response.json())
    .then(data => {
      updateFateModal(data)
    })
  }

  function updateFateModal(data) {
    document.getElementById('input-fate-name').value = data.fate.name
    document.getElementById('input-birthdate').value = data.horoscope.birth_iso
    document.getElementById('fate_horoscope_attributes_id').value = data.horoscope.id
    document.getElementById('fate_remark').value = data.fate.remark
    if(data.fate.avatar_id != undefined) {
      document.getElementById('fate_avatar_id').value = data.fate.avatar_id
      document.getElementById('fate_avatar_preview').src = data.fate.image_url
    }

    // change form method from 'POST' to 'PATCH'
    document.getElementById('new_fate').insertAdjacentHTML('afterbegin',
      '<input type="hidden" name="_method" value="PUT">'
    )
    document.getElementById('new_fate').action = `/lukhana/fates/${data.fate.id}`
  }
})

document.addEventListener('turbolinks:render', function(){
  renderSmileAvatar(avatarSmileUrl)

  function renderSmileAvatar(url) {
    let avatarPreview = document.querySelector('.fate-avatar--preview')
    if(avatarPreview != null && avatarPreview.innerHTML == "") {
      let avatarSmile = new Image()
      avatarSmile.id = 'fate_avatar_preview'
      avatarSmile.setAttribute('src', url)
      avatarPreview.appendChild(avatarSmile)
    }
  }
  
})
