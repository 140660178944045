document.addEventListener('turbolinks:load', function(){
  const paypalBtn = document.getElementById('paypal-button')
  if(paypalBtn != null) {
    paypal.Button.render({
      // Configure environment
      env: 'production',
      style: {
          label: 'buynow',
          fundingicons: true, // optional
          branding: true, // optional
          size:  'medium', // small | medium | large | responsive
          shape: 'rect',   // pill | rect
          color: 'gold'   // gold | blue | silver | black
      },
      client: {
        production: 'AZXo1O6azmqn_f2lN5XfURtmQoxzSkiK8II4Yd40Io1LTLeciN5_Y90HwkLsPs9hMJjR7WA3BR1KvDuv'
      },

      // Enable Pay Now checkout flow (optional)
      commit: true,

      // Set up a payment
      payment: function(data, actions) {
        return actions.request({
            url: '/paypals',
            method: 'POST',
            headers: {
              'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
              'Accept': 'application/json'
            },
            data: {
              plan: document.querySelector("[data-plan]").dataset.plan
            }
          })
          .then(function(res) {
            return res.id;
          });
      },

      // Execute the payment
      onAuthorize: function(data, actions) {
        return actions.request({
          url: '/paypals/execute',
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
          },
          data: {
            paymentID: data.paymentID,
            payerID:   data.payerID,
          }
        })
        .then(function(res) {
          // redirect to auguries index path using Turbolink.visit
          if(res.error == null) {
            //console.log(res)
            window.location.replace(`/คำพยากรณ์รายเดือน?profile_id=${res.profile_id}`)
          } else {
            console.log(res.error) 
          } 
        });
      },
      
      onCancel: function(data, actions) {
        return actions.redirect() 
      },
      onError: function(error) {
        console.log('onError:')
        return console.log(error)
        //return alert(error)
      }
    }, '#paypal-button');

  }
})
