import './sidemenu.scss';

document.addEventListener('turbolinks:load', function(){
  const wrapperMain = document.querySelector('.wrapper--main')
  const wrapperSidemenu = document.querySelector('.wrapper--sidemenu')
  const overlaySidemenu = document.querySelector('.sidemenu--overlay')

  var profileAvatar = document.getElementById('profile-avatar')
  var sidemenuArrow = document.querySelector('.sidemenu--arrow')

  if(profileAvatar != null) {
    // toggle sidemenu by clicking avatar
    profileAvatar.addEventListener('click', function(e){
      wrapperMain.classList.toggle('wrapper--main--slide') 
      wrapperSidemenu.classList.toggle('sidemenu--show') 
      overlaySidemenu.classList.toggle('show')

      // close sidemenu by clicking on overlay
      overlaySidemenu.addEventListener('click', closeSidemenu)
    })

    // close sidemenu by clicking arrow
    sidemenuArrow.addEventListener('click', closeSidemenu)


    // close sidemenu when any lists are clicked
    let menuLists = document.querySelectorAll('.sidemenu li a')
    menuLists.forEach(function(list){
      list.addEventListener('click', closeSidemenu) 
    })
  }

  function closeSidemenu(){
    wrapperMain.classList.remove('wrapper--main--slide') 
    wrapperSidemenu.classList.remove('sidemenu--show') 
    overlaySidemenu.classList.remove('show')

    overlaySidemenu.removeEventListener('click', closeSidemenu)
  }
})
