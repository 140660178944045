import React from 'react'

function TableHead() {
  return(
    <thead className="table-head">
      <tr>
        <th>ดาว</th>
        <th>ราศี</th>
        <th>องศา</th>
        <th>ลิปดา</th>
        <th className="text-nowrap">ตรียางค์</th>
        <th className="text-nowrap">ลูกพิษ</th>
        <th className="text-nowrap">นวางค์</th>
        <th className="text-nowrap">นักษัตร</th>
        <th>บาท</th>
        <th className="text-nowrap">ประเภทฤกษ์</th>
        <th className="text-nowrap">หมวดฤกษ์</th>
      </tr>
    </thead>
  )
}

const AstroItem = (props) => {
  let classRow = props.astro.name == "ลัคนา" ? "row-lukhana" : ""
  return(
    <tr className={classRow}>
      <td>{ props.astro.name }</td>
      <td>{ props.astro.zodiac }</td>
      <td>{ props.astro.angle }</td>
      <td>{ props.astro.arcmin }</td>
      <td className="text-nowrap">{ props.astro.triyang }</td>
      <td className="text-nowrap">{ props.astro.pid }</td>
      <td className="text-nowrap">{ props.astro.nawang }</td>
      <td className="text-nowrap">{ props.astro.constellation }</td>
      <td>{ props.astro.baht }</td>
      <td className="text-nowrap">{ props.astro.auspicious }</td>
      <td className="text-nowrap">{ props.astro.auspicious_grand }</td>
    </tr>
  )
}

class AstroTable extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div id="tables-tab-content" className="tab-content">
        <div className={`tab-pane fade ${this.props.show ? "show active" : ""} horo-table`} id={`table-${this.props.name}`} role="tabpanel" aria-labelledby={`tab-${this.props.name}`}>
          <table className="table table-sm table-responsive">
            <TableHead />
            <tbody>
              { 
                this.props.astros.map((astro, i) => {
                  return <AstroItem key={i} astro={astro} />
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default AstroTable

