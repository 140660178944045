import Rails from "@rails/ujs"
import ImagesLoaded from "imagesloaded"
import Masonry from "masonry-layout/masonry"

document.addEventListener('turbolinks:load', () => {
  const thumbnailImages = document.querySelectorAll('.about--photo--thumbnail')
  if(thumbnailImages.length > 0) {
    const backDrop = document.createElement('div')
    console.log(`photoShow is ${photoShow}`)

    backDrop.className = "backdrop"
    backDrop.onclick = (e) => { 
      if(!e.target.closest('.about--photo--show')) {
        e.target.className = "backdrop" 
      }
    }
    document.body.append(backDrop)

    const photoShow = document.createElement('div')
    const imgLarge = document.createElement('img')
    photoShow.className = "about--photo--show"
    photoShow.append(imgLarge)
    backDrop.append(photoShow)

    imgLarge.onload = (e) => {
      photoShow.style.top = `calc((100vh - ${e.target.height}px)/2)`
    }

    thumbnailImages.forEach(thumbnail => {
      thumbnail.onclick = (e) => {
        backDrop.classList.toggle('backdrop__show')
        imgLarge.src = e.target.parentElement.dataset.photoLargeUrl 

        // always remove photo caption then update it later
        if(photoShow.querySelector('.about--photo--caption')) { 
          photoShow.lastElementChild.remove() 
        }

        if(document.querySelector('[data-controller="photo-activity"]') != null) {
          updateCaption(e)
        } else {
          displayOnlyCaption(e)
        }
      } // thumbnail onclick
    }) //thumbnailImages

    function displayOnlyCaption(e) {
      console.log('display only caption')
      const caption_value = e.target.dataset.caption
      const caption = document.createElement('div')
      caption.className = "about--photo--caption about--photo--caption__display"
      caption.textContent = caption_value

      photoShow.append(caption)
    }

    function updateCaption(e) {
      const photo_id = e.target.dataset.photoId
      const caption_value = e.target.dataset.caption

      const captionText = document.createElement('textarea')
      //captionText.type = "text"
      captionText.rows = "3"
      captionText.className = "caption--text"
      captionText.placeholder = "กรุณาใส่แคปชั่น"
      captionText.value = caption_value

      const captionSubmit = document.createElement('input')
      captionSubmit.type = "submit"
      captionSubmit.className = "btn btn--submit caption--submit"
      captionSubmit.value = "Update"

      const caption = document.createElement('div')
      caption.className = "about--photo--caption"
      caption.append(captionText)
      caption.append(captionSubmit)

      //if(photoShow.querySelector('.about--photo--caption')) { 
      //  photoShow.lastElementChild.remove() 
      //}
      photoShow.append(caption)

      // update caption to the server
      captionSubmit.onclick = (e) => {
        const updateCaptionUrl = `/photo_activities/${photo_id}/caption`
        let formData = new FormData
        formData.append('photo[caption]', caption.firstElementChild.value)

        fetch(updateCaptionUrl, {
          method: 'PATCH',
          body: formData,
          headers: {
            'X-CSRF-Token': Rails.csrfToken()
          },
          credentials: 'same-origin'
        })
        .then(response => response.json())
        .then(data => {
          let updatedPhoto = document.querySelector(`[data-photo-id="${data.id}"]`)
          updatedPhoto.dataset.caption = data.caption
          backDrop.className = "backdrop"
        })
      } 
    } //updateCaption

    ImagesLoaded(document.querySelector('.about--photos'), function(){
      console.log('all images are loaded')
      const masonry = new Masonry('.about--photos', {
        itemSelector: '.about--photo',
        columnWidth: '.about--photo',
        percentPosition: true,
        //initLayout: false
      })
    })
    
    //masonry.layout()
  }
})
