import * as d3 from "d3";

const AstroChart = {
  lakhana_width: 22,
  frame_width: 640,
  frame_height: 512,
  circle_width: 280,
  circle_height: 280,
  offset() { return 0.15 * this.circle_width },
  center_x() { return this.circle_width/2 + this.offset()/2 },
  center_y() { return this.circle_height/2 + this.offset()/2 },
  r() { return (this.circle_width - this.offset())/2 },
  gap_center() { return 0.15 * this.circle_width },
  gap_half() { return this.gap_center()/2 },
  gap_outer() { return 0.1333333 * this.offset() },
  r2() { return this.r() + this.gap_outer() },
  r3() { return this.r2() + this.lakhana_width } 
};


function draw_chart(astros, travel) {
  //console.log(">> drawing chart / travel: " + travel)
  //console.log(astros)
  AstroChart.data = astros

  var svg = d3.select("#chart-svg")
  //.attr("viewBox", `0 0 ${AstroChart.frame_width} ${AstroChart.frame_height}`);
    .attr("viewBox", `0 0 640 512`);
  
  var canvas = d3.select("#canvas")
    .attr("transform", `translate(${AstroChart.frame_width/2}, ${AstroChart.frame_height/2})`)
    .append("g")
    .attr("id", "chart-base");

  const r = AstroChart.r(),
      gap_half = AstroChart.gap_half(),
    gap_center = AstroChart.gap_center(),
    r2 = AstroChart.r2(),
    r3 = AstroChart.r3(),
    lakhana_width = AstroChart.lakhana_width,
    center_x = AstroChart.center_x(),
    center_y = AstroChart.center_y(),
    offset = AstroChart.offset();

  var canvas_astro_origin = d3.select("#canvas")
    .append("g")
    .attr("id", "astro-origin");

  //always not show lakhana label
  draw_lakhana(canvas, false);
  draw_scale(canvas);

} //draw_chart

function draw_lakhana(canvas, label=true) {
  const lakhana_width = AstroChart.lakhana_width,
    center_x = AstroChart.center_x(),
    center_y = AstroChart.center_y(),
    r2 = AstroChart.r2() + 10,
    r3 = AstroChart.r3();

  const lakhana_rasi = AstroChart.data[12].zodiac;

  var lakhanas = ["ตนุ", "กดุมภะ", "สหัสชะ", "พันธุ", "ปุตตะ", "อริ", "ปัตนิ", "มรณะ", "สุภะ", "กัมมะ", "ลาภะ", "วินาศ"]
    .map((n) => ({name: n, space: 30}))
  var arc = d3.arc()
    .outerRadius(r3)
    .innerRadius(r2)

  var lakhana_start_angle = (12 - lakhana_rasi + 1) * 30

  var arcs = d3.pie()
    .endAngle(lakhana_start_angle * Math.PI/180 + 2*Math.PI)
    .startAngle(lakhana_start_angle * Math.PI/180)
    .value((d) => d.space).sort(null);
    
  // Make nice label on arc curve according this link
  // https://www.visualcinnamon.com/2015/09/placing-text-on-arcs.html
  var outlineLakhana = canvas.append("g").attr("class", "outline-lakhana");
  var group_hidden_arcs = outlineLakhana.append("g")
    .attr("transform", `rotate(-15 0 0)`)

  var lakhana_places = outlineLakhana.append("g")
    .attr("transform", `rotate(-15 0 0)`)
    .attr("class", "lakhana-arcs")
    .selectAll(".lakhana-arc")
    .data(arcs(lakhanas))
  .enter().append("path")
    .attr("class", "lakhana-arc")
    .attr("d", arc)
    .each(function(d, i){
      //A regular expression that captures all in between the start of a string (denoted by ^) 
      //and the first capital letter L
      var firstArcSection = /(^.+?)L/;

      //Grab everything up to the first Line statement/
      var newArc = firstArcSection.exec( d3.select(this).attr("d") )[1];

      //Replace all the comma's so that IE can handle it -_-
      newArc = newArc.replace(/,/g, " ");

      //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2) 
      //flip the end and start position
      //if (d.endAngle > 90 * Math.PI/180 && d.endAngle <= 270 * Math.PI/180) {
      if( 
        (150 * Math.PI/180) < d.endAngle && d.endAngle <= (270 * Math.PI/180) || 
        (510 * Math.PI/180) <= d.endAngle && d.endAngle < (630 * Math.PI/180) 
      ) {
        var startLoc 	= /M(.*?)A/,		//Everything between the capital M and first capital A
          middleLoc 	= /A(.*?)0 0 1/,	//Everything between the capital A and 0 0 1
          endLoc 		= /0 0 1 (.*?)$/;	//Everything between the 0 0 1 and the end of the string (denoted by $)
        //Flip the direction of the arc by switching the start and end point (and sweep flag)
        var newStart = endLoc.exec(newArc)[1];
        var newEnd = startLoc.exec(newArc)[1];
        var middleSec = middleLoc.exec(newArc)[1];
        
        //Build up the new arc notation, set the sweep-flag to 0
        newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
      }//if
      //Create a new invisible arc that the text can flow along
      group_hidden_arcs.append("path")
            .attr("class", "hidden-lakhana-arcs")
            .attr("id", "lakhana_arc_"+i)
            .attr("d", newArc)
            .style("fill", "none");
    })//each

  outlineLakhana.append("g")
    .attr("transform", `rotate(-15 0 0)`)
    .attr("class", "lakhana-labels")
    .selectAll(".lakhana-label")
    .data(arcs(lakhanas.reverse()))
  .enter().append("text")
    .attr("class", (d) => (label ? "lakhana-label" : "lakhana-label hide"))
  .attr("dy", (d) => {
    if( 
      (150 * Math.PI/180) < d.endAngle && d.endAngle <= (270 * Math.PI/180) || 
      (510 * Math.PI/180) <= d.endAngle && d.endAngle < (630 * Math.PI/180) 
    ) {
      return -lakhana_width/2.5;
    } else {
      return lakhana_width/1.5;
    }
  })
  .append("textPath")
    .attr("startOffset", "50%")
    .style("text-anchor", "middle")
    .attr("xlink:href", (d,i) => `#lakhana_arc_${i}`)    
    .text((d) => d.data.name)

  outlineLakhana.append("circle")
    .attr("class", "circle-outer")
    .attr("cx", center_x)
    .attr("cy", center_y)
    .attr("r", r2);
} //draw_lakhana()



function draw_scale(canvas) {
  var r3 = AstroChart.r3();
  var scale_astro = canvas.append("g").attr("class", "scale-astro");

  scale_astro.append("g")
    .attr("class", "ticks")
    .selectAll("g")
    .data(d3.range(0, 360, 5)).enter()
    .append("g")
      .attr("class", function(d){
        var full_tick = tick_angle(d + 15) % 10
        var full_tick_long = tick_angle(d + 15) % 30
        if(full_tick) {
          return "tick-5"
        } else {
          return full_tick_long ? "tick-10" : "tick-30"
        }
      })
      .attr("transform", function(d) {
        return "rotate(" + tick_angle(d) + ")";
      })
    .append("path")
      .attr("d", function(d) {
        var full_tick = tick_angle(d + 15) % 10
        var full_tick_long = tick_angle(d + 15) % 30
        if(full_tick) {
          return `M0 ${r3 + 10} l0 -5`
        } else {
          return full_tick_long ? `M0 ${r3 + 10} l0 -10` : `M0 ${r3 + 10} l0 -23`
        } 
      });

  scale_astro.append("circle")
    .attr("class", "scale-outer")
    .attr("cx", 0)
    .attr("cy", 0)
    .attr("r", r3 + 10);

  function tick_angle(d) {
    return d - 15;
  }
} //draw_scale



function draw_astro(data_astros, travel=false, astro_type="origin") {
  //console.log(">> drawing astro " + astro_type.toUpperCase())

  var canvas = d3.select("#canvas")
    .append("g")
    .attr("id", "astro-origin");

  if(travel) { canvas.attr("id", "astro-travel") }

  const gap_half = AstroChart.gap_half(),
    gap_center = AstroChart.gap_center(),
    r2 = AstroChart.r2(),
    r3 = AstroChart.r3(),
    lakhana_width = AstroChart.lakhana_width,
    center_x = AstroChart.center_x(),
    center_y = AstroChart.center_y(),
    offset = AstroChart.offset();

  let r = AstroChart.r() + 15;
  if(travel) {
    r = AstroChart.r() + AstroChart.lakhana_width + 5;
  }

  // inspire from https://bl.ocks.org/mbostock/4583749
  var points_astro = canvas.append("g").attr("class", "points-astro")
  var label_pointer = 20 + lakhana_width;
  var label_length = label_pointer + 5;

  var points = points_astro.selectAll("g")
    .data(data_astros).enter()
    .append("g")
      .attr("class", "point");

  var points_circle = points.append("circle")
    .attr("cx", (d) => r * Math.cos(astro_angle_radian(d)))
    .attr("cy", (d) => r * Math.sin(astro_angle_radian(d)))
    .attr("r", "0.1875rem")

  var labels_astro = canvas.append("g").attr("class", "labels-astro")

  var labels_text = labels_astro.selectAll("g")
    .data(data_astros).enter()
    .append("g")
    .attr("class", "label")
    .append("text")
    .attr("x", (d) => {
      var label_x = (r2 + label_pointer) * Math.cos(astro_angle_radian(d));
      return (astro_angle(d) < -90 && astro_angle(d) > -270) ? label_x - 30 : label_x + 30;
    })
    .attr("y", (d) => (r2 + label_pointer) * Math.sin(astro_angle_radian(d)))
    .attr("dy", ".5rem")
    .attr("font-stretch", "condensed")
    .style("stroke", "none")
    .style("text-anchor", function(d) { return -astro_angle(d) < 270 && -astro_angle(d) > 90 ? "end" : null; })
    .text((d, i) => {
        return (
          d.rasi[1] + ", " + d.angle + "°, " + d.triyang.match(/\s\d/)[0].replace(/\s/g,'') + ":" + d.nawang.match(/\s\d/)[0].replace(/\s/g,'') + ", " + d.auspicious_grand
        )}
    );

  var points_line = points.append("polyline")
    .attr("points", function(d, i){
      var circle = points_circle.filter((d,j) => i == j);
      var p1 = [circle.attr("cx"), circle.attr("cy")];
      var p2 = [(r2 + label_pointer)*Math.cos(astro_angle_radian(d)), (r2 + label_pointer)*Math.sin(astro_angle_radian(d))];
      var p3 = (astro_angle(d) < -90 && astro_angle(d) > -270) ? [p2[0] - 30, p2[1]] : [p2[0] + 30, p2[1]] 
      return [p1, p2, p3];
    })

  relax(labels_text);


  function relax(labels) {
    // https://www.safaribooksonline.com/blog/2014/03/11/solving-d3-label-placement-constraint-relaxing/
    // http://jsfiddle.net/thudfactor/B2WBU/
    //
    // constraint relaxation on labels
    var labels_text = labels;
    var alpha = 0.5;
    var spacing = 15;

    var again = false;
    labels_text.each(function(d,i) {
      let text_a = this;
      let text_d3_a = d3.select(text_a);
      let y1 = text_d3_a.attr("y");

      labels_text.each(function(b,j) {
        let text_b = this;
        let text_d3_b = d3.select(text_b); 
        let y2 = text_d3_b.attr("y");

        // a & b are the same element and don't collide.
        if(text_a == text_b) return;
        
        // a & b are on opposite sides of the chart and will not collide
        if(text_d3_a.attr("text-anchor") != text_d3_b.attr("text-anchor")) return;

        var delta_y = y1 - y2;

        if(Math.abs(delta_y) > spacing) return;

        again = true;
        var sign = delta_y > 0 ? 1 : -1;
        var adjust = sign * alpha;

        text_d3_a.attr("y", +y1 + adjust);
        text_d3_b.attr("y", +y2 - adjust);

        var point_line = points_line.filter((d, k) => (j === k))
        var pline = point_line.attr("points").split(",");
        pline.splice(3,1, text_d3_b.attr("y"));
        pline.splice(4,1, text_d3_b.attr("x"));
        pline.splice(5,1, text_d3_b.attr("y"));
        point_line.attr("points", pline);
      }); 
    });

    if(again) {
      setTimeout(relax(labels_text),20);
    }
  }//relax()

  function astro_angle(d) {
    return -(30*d.zodiac + d.angle -15 +90);
  }
  function astro_angle_radian(d) {
    return astro_angle(d) * Math.PI/180;
  }
  
} //draw_astro(astros)


export { draw_chart, draw_astro }
