//import moment from 'moment'
var moment = require('moment-timezone')
import React from 'react'
import SubmitIcon from '../../../images/lukhana/submit.png'
import { clearMainBackground, clearModalData, removeFateAvatar } from '../helper/helper'

class BirthForm extends React.Component {
  constructor(props) {
    super(props)
    this.monthTable = {
      1: { th: "มกราคม", en: "January" },
      2: { th: "กุมภาพันธ์", en: "February" },
      3: { th: "มีนาคม", en: "March" },
      4: { th: "เมษายน", en: "April" },
      5: { th: "พฤษภาคม", en: "May" },
      6: { th: "มิถุนายน", en: "June" },
      7: { th: "กรกฎาคม", en: "July" },
      8: { th: "สิงหาคม", en: "August" },
      9: { th: "กันยายน", en: "September" },
      10: { th: "ตุลาคม", en: "October" },
      11: { th: "พฤศจิกายน", en: "November" },
      12: { th: "ธันวาคม", en: "December" },
    }

    const momentTz = moment().tz(this.props.timezone)
    const minute = momentTz.format('mm')
    const hour = momentTz.format('HH')
    const day = momentTz.date()
    const month = momentTz.month() + 1
    const year = momentTz.year() + 543


    this.state = {
      era: 'buddhist',
      lang: 'th',
      targetMinute: minute,
      targetHour: hour,
      targetDay: day,
      targetMonth: this.monthTable[month]["th"],
      targetYear: year,
      errMsg: "",
      targetDateEn: momentTz.format('YYYY-MM-DDTHH:mm')
    }

    this.setMinute = this.setMinute.bind(this)
    this.setHour = this.setHour.bind(this)
    this.setDay = this.setDay.bind(this)
    this.setMonth = this.setMonth.bind(this)
    this.toggleEra = this.toggleEra.bind(this)
  }

  componentDidMount() {
    // update data-target-date for existing customer
    const init_birth = moment.unix(this.props.initBirth)
    this.setState({targetDateEn: init_birth.format('YYYY-MM-DDTHH:mm')})
  }

  enableSaveBtn() {
    const saveBtn = document.querySelector('[data-shortcut="save-record"]')
    if(saveBtn != null) {
      saveBtn.classList.remove("disable")
    }
  }
  hideFormHoro() {
    const horoscope = document.querySelector('#horoscope')
    const btnFormToggle = document.querySelector(".btn-form-horo--toggle")
    horoscope.classList.toggle("move-up")
    btnFormToggle.classList.toggle("rotate-down")
  }


  setBirthDate(event) {
    event.preventDefault();
    let {era, lang, targetMinute, targetHour, targetDay, targetMonth, targetYear } = this.state
    let year_common, month_index

    if(this.state.era == 'buddhist') {
      year_common = targetYear - 543
      month_index = this.getMonthIndex("th")
    } else {
      year_common = targetYear
      month_index = this.getMonthIndex("en")
    }
    
    let target_input = `${year_common}-${month_index}-${targetDay} ${targetHour}:${targetMinute}`
    let birthdate = moment(target_input, 'YYYY-M-DD HH:mm')
    if(!birthdate.isValid()) {
      let str = `${targetDay}/${month_index}/${year_common} is not a valid date`
      //console.log(str)
      this.setState({errMsg: str})
      return
    }

    let birthdate_with_t = birthdate.format('YYYY-MM-DDTHH:mm')
    let birthdate_in_timezone = birthdate.tz(this.props.timezone).unix()
    //this.props.setBirthDate(birthdate_with_t)
    this.props.setBirthDate(birthdate_in_timezone)
    this.enableSaveBtn()
    this.hideFormHoro()
    removeFateAvatar()
    clearModalData()
    clearMainBackground()
    this.setState({errMsg: "", targetDateEn: birthdate_with_t})

    // change current target date in Modal
    let modal_input_birthdate = document.getElementById("input-birthdate")
    modal_input_birthdate.value = birthdate_with_t
  }


  setDay(e) {
    this.setState({targetDay: parseInt(e.target.value)})
  }

  setMonth(e) {
    this.setState({targetMonth: e.target.value})
  }
  setYear(e) {
    this.setState({targetYear: parseInt(e.target.value)})
  }
  setHour(e) {
    this.setState({targetHour: e.target.value})
  }
  setMinute(e) {
    this.setState({targetMinute: e.target.value})
  }
  getMonthIndex(lang) {
    return Object.keys(this.monthTable).find(key => {
      return this.monthTable[`${key}`][lang] == this.state.targetMonth
    })
  }

  toggleEra(e) {
    const year = this.state.targetYear
    const previousEra = this.state.era
    let selected_month, selected_year, selected_lang, month_index 

    if(previousEra == 'buddhist') {
      month_index = this.getMonthIndex("th")
      selected_month = this.monthTable[month_index]["en"]
      selected_year = year - 543
      selected_lang = "en"
    } else {
      month_index = this.getMonthIndex("en")
      selected_month = this.monthTable[month_index]["th"]
      selected_year = year + 543
      selected_lang = "th"
    }
    this.setState({ 
      targetMonth: selected_month,
      targetYear: selected_year,
      lang: selected_lang,
      era: e.target.value
    })
  }

  toggleFormDisplay(e) {
    console.log("toggle form display")
  }

  render() {
    const today = moment()
    let thisday = (new Date()).getDate()
    let endYear = moment().year() + 10
    let showYear = this.state.era === 'buddhist' ? endYear + 543 : endYear
    let yearList = Array.from(new Array(showYear + 100), (x, i) => i)
                    .filter((x,i) => showYear-100 < i && i < showYear)
                    .sort((a,b) => b-a)

    const dayList = Array.from(new Array(31), (x, i) => i + 1)
    const hourList = Array.from(new Array(24), (x, i) => {
      let num = i.toString()
      return num.length == 1 ? `0${num}` : num
    })
    const minuteList = Array.from(new Array(60), (x, i) => {
      let num = i.toString()
      return num.length == 1 ? `0${num}` : num
    })

    return(
      <div className="wrap-form-horo">


        <form className="form-horo" acceptCharset="UTF-8" onSubmit={(e) => this.setBirthDate(e)}>
          <div className="wrap-form-group" data-target-date={this.state.targetDateEn}>
  <div className="label-form-group label-birthdate">วัน-เดือน-ปี เกิด</div>
            <div className="form-group input-birthdate">
              <select id="birth-date" className="custom-select" value={this.state.targetDay} onChange={this.setDay}>
                {
                  dayList.map((d) => <option key={`d-${d}`} value={d}>{d}</option>)
                }
              </select>
              <select id="birth-month" className="custom-select" value={this.state.targetMonth} onChange={this.setMonth}>
                { 
                  Object.keys(this.monthTable).map((k,i) => {
                    let m = this.monthTable[k][this.state.lang]
                    return <option key={`m-${k}`} monthindex={k} value={m}>{m}</option>
                  }) 
                }
              </select>

              <select id="birth-year" className="custom-select" value={this.state.targetYear} onChange={(e) => this.setYear(e)}>
                {
                  yearList.map((y) => <option key={y} value={y}>{y}</option> )
                }
              </select>
              {
                this.state.errMsg !== "" ? <div className="err-msg">{this.state.errMsg}</div>
                                        : <div></div>
              }
            </div>
          </div>

          <div className="wrap-form-group">
            <div className="label-form-group label-birthtime">เวลาเกิด</div>
            <div className="form-group input-birthtime">
              <select id="birth-hour" className="custom-select" value={this.state.targetHour} onChange={this.setHour}>
                {
                  hourList.map((h) => <option key={h} value={h}>{h}</option> )
                }
              </select>
              <select id="birth-min" className="custom-select" value={this.state.targetMinute} onChange={this.setMinute}>
                {
                  minuteList.map((m) => <option key={m} value={m}>{m}</option> )
                }
              </select>
            </div>
          </div>

          <div className="from-group radios-era">
            <div className="form-check form-check-inline radio">
              <input className="form-check-input" id="radio-era-be" name="year-era" type="radio" value="buddhist" checked={this.state.era === 'buddhist'} onChange={this.toggleEra} />
              <label className="form-check-label" htmlFor="radio-era-be">พุทธศักราช</label>
            </div>
            <div className="form-check form-check-inline radio">
              <input className="form-check-input" id="radio-era-ce" name="year-era" type="radio" value="common" checked={this.state.era === 'common'} onChange={this.toggleEra} />
              <label className="form-check-label" htmlFor="radio-era-ce">คริสต์ศักราช</label>
            </div>
          </div>

          <button id="submit-btn" role="button" type="submit"><i className="fab fa-wpexplorer" aria-hidden="true"></i></button>
        </form>
      </div>
    )
  }
}

export default BirthForm;
