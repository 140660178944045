import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import './spinner_double-bounce.css'

export default class extends Controller {
  static targets = ["item", "availables", "spinner", "btnSubmitPastMonth"]

  initialize() {
    //console.log("initial augury fetch")
  }

  monthName(index) {
    const monthNameList = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
    return monthNameList[index]
  }

  updateRemainingCredit() {
    // Update remaining credit
    let auguryRemain = document.querySelector('.augury--credit--remain--amount')
    let newRemaining = parseInt(auguryRemain.textContent) - 1
    auguryRemain.textContent = newRemaining
  }

  updateAuguryList(data){
    const monthAndYear = `${data.month}-${data.year}`

    // REMOVE the fetched augury month button
    let fetchedAugury = document.getElementById(`augury-fetch-${monthAndYear}`) 
    if(fetchedAugury != null) {
      fetchedAugury.remove()
    }
    // otherwise remove the SelectOption of the past augury list
    let selectedOption = document.querySelector(`option[value="${monthAndYear}"]`)
    if(selectedOption != null) {
      selectedOption.remove()
    }

    // ADD the fetched augury month to the top of the existing augury list
    let auguryMonth = document.getElementById(`augury-${monthAndYear}`)
    let auguryMonthHTML = `
      <div id="augury-${monthAndYear}" class="augury--list"></div>
    `
    let auguryItemHTML = `
      <div class="augury--item__month" style="color:steelblue;">
        <div class="augury--item--group augury--item--group__header">
          <div class="augury--item">${this.monthName(data.month)} <span>${data.year}</span></div>
          <div class="augury--item--time">${data.created_at}</div>
          <button class="augury--item--explore">
            <svg viewBox="0 0 320 512" width="22" height="22">
              <use xlink:href="#arrow-down" />
            </svg>
          </button>
        </div>
        <blockquote class="augury--item--group augury--item--group__body">
          ${data[`body_${this.locale}`]}
        </blockquote>
      </div>
    `

    let auguryLists = document.querySelector('.augury-lists') 
    let auguryList
    const auguryListId = `augury-${data.month}-${data.year}`

    if(auguryMonth == null){
      auguryList = document.createElement('div')
      auguryList.className = "augury--list"
      auguryList.id = auguryListId
    } else {
      auguryList = document.getElementById(auguryListId)
    }
    auguryList.insertAdjacentHTML('afterbegin', auguryItemHTML)
    auguryLists.prepend(auguryList)

    // toggle augury content
    auguryList.addEventListener('click', function(){
      toggleAuguryBody(auguryList)
    })

    function toggleAuguryBody(auguryList){
      auguryList.querySelector("blockquote").classList.toggle("augury--item--group__hide")
      let exploreBtn = auguryList.querySelector(".augury--item--explore")
      exploreBtn.firstElementChild.classList.toggle("arrow-rotate") 
    }
  }

  scrollToTheAugury(data) {
    const auguryId = `augury-${data.month}-${data.year}`
    document.getElementById(auguryId).scrollIntoView({ behavior: 'smooth' })
  }

  updateNotificationCount() {
    let notificationUnreadCounts = document.querySelectorAll(".notification--unread--count")
    notificationUnreadCounts.forEach(x => {
      let lastCount = parseInt(x.textContent)
      x.textContent = lastCount + 1
    })
  }

  selectPastMonth(e) {
    const [month, year] = e.target.value.split('-')
    this.btnSubmitPastMonthTarget.dataset.month = month
    this.btnSubmitPastMonthTarget.dataset.year = year
  }

  submit(e) {
    if(!window.confirm("Are you sure?")) {
      return
    }

    this.showSpinning()

    const selectedItem = e.target
    const month = selectedItem.dataset.month
    const year = selectedItem.dataset.year
    const profile_id = this.data.get('profile')

    let formData = new FormData
    formData.append('augury[month]', month)
    formData.append('augury[year]', year)
    formData.append('augury[profile_id]', profile_id)

    const url = `/auguries/fetch.json`
    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      //console.log(data)
      this.spinnerTarget.remove()
      if(data.error != undefined){ return }
      this.updateRemainingCredit()
      this.updateAuguryList(data)
      this.scrollToTheAugury(data)
      this.updateNotificationCount()
    })
  }

  showSpinning() {
    const spinDom = `
      <div class="spinner" data-target='augury-fetch.spinner'>
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    `
    this.availablesTarget.insertAdjacentHTML('afterbegin', spinDom)
  }

  get locale() {
    return document.querySelector("meta[name='locale']").content
  }
}
