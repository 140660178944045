import moment from 'moment'
import React from 'react'
//import { clearMainBackground, clearModalData, removeFateAvatar } from '../helper/helper'

class BirthFormProfile extends React.Component {
  constructor(props) {
    super(props)
    this.monthTable = {
      1: { th: "มกราคม", en: "January" },
      2: { th: "กุมภาพันธ์", en: "February" },
      3: { th: "มีนาคม", en: "March" },
      4: { th: "เมษายน", en: "April" },
      5: { th: "พฤษภาคม", en: "May" },
      6: { th: "มิถุนายน", en: "June" },
      7: { th: "กรกฎาคม", en: "July" },
      8: { th: "สิงหาคม", en: "August" },
      9: { th: "กันยายน", en: "September" },
      10: { th: "ตุลาคม", en: "October" },
      11: { th: "พฤศจิกายน", en: "November" },
      12: { th: "ธันวาคม", en: "December" },
    }

    let  [minute, hour] = ['00', '06']
    let  [day, month, year] = [moment().date() - 1, moment().month() + 1, moment().year() + 543]

    this.state = {
      era: 'buddhist',
      lang: 'th',
      targetMinute: minute,
      targetHour: hour,
      targetDay: day,
      targetMonth: this.monthTable[month]["th"],
      targetYear: year,
      errMsg: "",
      targetDateEn: moment().format('YYYY-MM-DDTHH:mm')
    }

    this.setMinute = this.setMinute.bind(this)
    this.setHour = this.setHour.bind(this)
    this.setDay = this.setDay.bind(this)
    this.setMonth = this.setMonth.bind(this)
    this.toggleEra = this.toggleEra.bind(this)
  }

  componentDidMount() {
    // update data-target-date for existing customer
    const birth = this.props.initBirth
    if(birth == undefined || birth == '') { return }

    const init_birth = moment(this.props.initBirth)
    const [year, month, day, hour, minute] = [
      init_birth.year() + 543,
      init_birth.month() + 1,
      init_birth.date(),
      init_birth.format('HH'),
      init_birth.format('mm')
    ]
    this.setState({
      targetYear: year,
      targetMonth: this.monthTable[month]["th"],
      targetDay: day,
      targetHour: hour,
      targetMinute: minute
    })
    this.setState({targetDateEn: init_birth.format('YYYY-MM-DDTHH:mm')})
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('BirthProfileForm calls componentDidUpdate')
    if(this.state !== prevState) {
      this.determineBirthDate()
    }
  }

  determineBirthDate() {
    let {era, lang, targetMinute, targetHour, targetDay, targetMonth, targetYear } = this.state
    let year_common, month_index

    if(this.state.era == 'buddhist') {
      year_common = targetYear - 543
      month_index = this.getMonthIndex("th")
    } else {
      year_common = targetYear
      month_index = this.getMonthIndex("en")
    }
    
    const target_input = `${year_common}-${month_index}-${targetDay} ${targetHour}:${targetMinute}`
    const birthdate = moment(target_input, 'YYYY-M-DD HH:mm:ss') 
    const birthdate_with_t = birthdate.format('YYYY-MM-DDTHH:mm:ssZ')

    this.props.setBirthDate(birthdate_with_t)
    //this.setState({errMsg: "", targetDateEn: birthdate_with_t})
  }

  setDay(e) { this.setState({targetDay: parseInt(e.target.value)}) }
  setMonth(e) { this.setState({targetMonth: e.target.value}) }
  setYear(e) { this.setState({targetYear: parseInt(e.target.value)}) }
  setHour(e) { this.setState({targetHour: e.target.value}) }
  setMinute(e) { this.setState({targetMinute: e.target.value}) }
  getMonthIndex(lang) {
    return Object.keys(this.monthTable).find(key => {
      return this.monthTable[`${key}`][lang] == this.state.targetMonth
    })
  }

  toggleEra(e) {
    const year = this.state.targetYear
    const previousEra = this.state.era
    let selected_month, selected_year, selected_lang, month_index 

    if(previousEra == 'buddhist') {
      month_index = this.getMonthIndex("th")
      selected_month = this.monthTable[month_index]["en"]
      selected_year = year - 543
      selected_lang = "en"
    } else {
      month_index = this.getMonthIndex("en")
      selected_month = this.monthTable[month_index]["th"]
      selected_year = year + 543
      selected_lang = "th"
    }
    this.setState({ 
      targetMonth: selected_month,
      targetYear: selected_year,
      lang: selected_lang,
      era: e.target.value
    })
  }

  render() {
    const today = moment()
    let thisday = (new Date()).getDate()
    let endYear = moment().year() + 10
    let showYear = this.state.era === 'buddhist' ? endYear + 543 : endYear
    let yearList = Array.from(new Array(showYear + 100), (x, i) => i)
                    .filter((x,i) => showYear-100 < i && i < showYear)
                    .sort((a,b) => b-a)

    const dayList = Array.from(new Array(31), (x, i) => i + 1)
    const hourList = Array.from(new Array(24), (x, i) => {
      let num = i.toString()
      return num.length == 1 ? `0${num}` : num
    })
    const minuteList = Array.from(new Array(60), (x, i) => {
      let num = i.toString()
      return num.length == 1 ? `0${num}` : num
    })

    return(
      <div className="wrap-birthform">
          <div className="form--group" data-target-date={this.state.targetDateEn}>
            <div className="form--group--title">วัน-เดือน-ปี เกิด</div>
            <div className="birthdate">
              <select id="birth-date" className="custom-select" value={this.state.targetDay} onChange={this.setDay}>
                {
                  dayList.map((d) => <option key={`d-${d}`} value={d}>{d}</option>)
                }
              </select>
              <select id="birth-month" className="custom-select" value={this.state.targetMonth} onChange={this.setMonth}>
                { 
                  Object.keys(this.monthTable).map((k,i) => {
                    let m = this.monthTable[k][this.state.lang]
                    return <option key={`m-${k}`} monthindex={k} value={m}>{m}</option>
                  }) 
                }
              </select>

              <select id="birth-year" className="custom-select" value={this.state.targetYear} onChange={(e) => this.setYear(e)}>
                {
                  yearList.map((y) => <option key={y} value={y}>{y}</option> )
                }
              </select>
              {
                this.state.errMsg !== "" ? <div className="err-msg">{this.state.errMsg}</div>
                                        : <div></div>
              }
            </div>
          </div>

          <div className="form--group">
            <div className="form--group--title">เวลาเกิด</div>
            <div className="birthtime">
              <select id="birth-hour" className="custom-select" value={this.state.targetHour} onChange={this.setHour}>
                {
                  hourList.map((h) => <option key={h} value={h}>{h}</option> )
                }
              </select>
              <select id="birth-min" className="custom-select" value={this.state.targetMinute} onChange={this.setMinute}>
                {
                  minuteList.map((m) => <option key={m} value={m}>{m}</option> )
                }
              </select>
            </div>
          </div>

          <div className="form--group form--group__radios">
            { /* <div className="form--radio form--radio__inline"> */ }
            <div className="form-check form-check-inline">
              <input className="form-check-input" id="radio-era-be" name="year-era" type="radio" value="buddhist" checked={this.state.era === 'buddhist'} onChange={this.toggleEra} />
              <label className="form-check-label label__radio" htmlFor="radio-era-be">พุทธศักราช</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" id="radio-era-ce" name="year-era" type="radio" value="common" checked={this.state.era === 'common'} onChange={this.toggleEra} />
              <label className="label__radio" htmlFor="radio-era-ce">คริสต์ศักราช</label>
            </div>
          </div>

      </div>
    )
  }
}

export default BirthFormProfile;
