import React from 'react';
import avatar_dummy from '../../../images/lukhana/smile_small.png';

class AstroFate extends React.Component  {
  constructor(props) {
    super(props)
    this.state = {
      allowEdit: true
    }
  }

  render() {
    let astro_preview = document.querySelector(".astro-preview")
    return(
      <div className="astro--fate--list">
        { 
          Object.keys(this.props.fate).length === 0 ? "" :
          <div className="astro--fate astro--fate__avatar">
            <img src={this.props.fate.avatar_url == "" ? avatar_dummy : this.props.fate.avatar_url} alt="" />
            <span>{this.props.fate.name}</span>
          </div>
        }
        <div className="astro--fate astro--fate__save">
          <a className={ astro_preview == null ? "disable" : "" } role="button" data-shortcut="save-record" data-toggle="modal" data-target="#saveCustomerModal">
            <i className="fas fa-edit"></i> 
          </a>
        </div>
        <div className="astro--fate astro--fate__stock">
          <a href="/lukhana/fates" title="คลังดวงชะตา">
            <i className="fas fa-book"></i>
          </a>
        </div>
      </div>
    )
  }
}

export default AstroFate
