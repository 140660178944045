import moment from 'moment'
import React from 'react'
import ChartBase from './ChartBase'
import { draw_astro } from './models/horo_chart.js'
//import SelectAstroType from './SelectAstroType';

class AstroOrigin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      astros: this.props.astros
    }
  }

  componentDidMount() {
    if(this.props.display) {
      draw_astro(this.state.astros)
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.display != this.props.display) {
      if(nextProps.display == true) {
        this.setState({astros: nextProps.astros})
        draw_astro(this.state.astros)
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    //return nextProps.displayChart.origin == true
    return nextProps.display
  }

  render() {
    return (<div className="astro-origin">Astro Origin</div>)
  }
} //AstroOrigin

class AstroTravel extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    if(this.props.travel) {
      draw_astro(this.props.astros_travel, true, "travel")
    }
  }
  render() {
    return (<div className="astro-travel">Astro Travel</div>)
  }
} //AstroTravel

class AstroSomput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      astros: this.props.astros_somput
    }
  }

  componentDidMount() {
    if(this.props.display) {
      draw_astro(this.state.astros, false, "somput")
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.display != this.props.display && nextProps.display == true) {
      this.setState({astros: nextProps.astros_somput}) 
      draw_astro(this.state.astros, false, "somput")
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    //return nextProps.travel != true
    //return nextProps.displayChart.somput == true
    return nextProps.display
  }

  render() {
    return (<div className="astro-origin">Astro Somput</div>)
  }
} //AstroSomput


class RasiElement extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return(
      <div key={`rasi-${this.props.rasi}`} className={`rasi rasi-${this.props.rasi}${this.props.rasiTravel ? (" rasi-travel-" + this.props.rasi) : ""}`} >
      { this.props.values.map((v) => {
          return <span key={v}>{v}</span>
        }) 
      }
      </div>
    )
  }
} //RasiElement

class AstroChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      astros: [],
      astros_travel: [],
      astros_somput: [],
      travel: false,
      toggleTravel: false,
      displayChart: { origin: true, travel: false, somput: false }
    }
    this.rasisData = this.rasisData.bind(this)
  }

  componentWillMount() {
    this.setState({
      astros: this.props.astros,
      astros_travel: this.props.astros_travel,
      astros_somput: this.props.astros_somput,
      travel: this.props.travel,
      displayChart: this.props.displayChart
    })
  }

  // --> Don't know why componentWillReceiveProps is not being called ???
  //componentWillReceiveProps(nextProps){
  //  console.log("call component will receive props in AstroChart ***")
  //  if(nextProps.displayChart != this.props.displayChart) {
  //    console.log("update displayChart in AstroChart")
  //    this.setState({displayChart: nextProps.displayChart})
  //  }
  //}

  rasisData(astros) {
    let rasis = astros.map(astro => astro.rasi)
    let data = {}
    rasis.forEach((r) => {
      (data[r[0]] == undefined) ? data[r[0]] = [r[1]]
                                      : data[r[0]].push(r[1])  
    })
    return data
  }

  toggleTravel(e) {
    if(this.state.toggleTravel != true) {
      let today = new Date()
      let d = moment(new Date(today.setDate(today.getDate()))).format('YYYY-MM-DDTHH:ss')
      this.getAstroTravel(d)
    } else {
      this.setState({
        toggleTravel: false,
        travel: false
      })
    }
  }

  getAstroTravel(d) {
    //console.log(`get astro travel data for ${d}`)
    let url = `/horoscopes/astro?birth=${d}`
    let userEmail = document.querySelector("meta[name='user-email']").content
    let userToken = document.querySelector("meta[name='astro-token']").content

    //console.log("fetching Astro TRAVEL data from " + url) 
    fetch(url,
      { 
        method: 'GET',
        headers: {
          "X-User-Email": userEmail,
          "X-User-Token": userToken
        }
      } 
    )
      .then(response => response.json())
      .then(horo => {
        this.setState({
          astros_travel: horo.astros,
          travel: true,
          toggleTravel: true
        })
      })
      .catch(error => console.log('error! ', error))
  }

  render() {
    const data_origin = this.rasisData(this.props.astros)
    const data_travel = this.rasisData(this.state.astros_travel)
    const data_somput = this.rasisData(this.state.astros_somput)
    let key_origin = `origin-${Date.now()}`
    let key_travel = `travel-${Date.now()}`
    let key_somput = `somput-${Date.now()}`

    moment.locale('th')
    
    //console.log("birth_travel = " + this.props.birth_travel)
    let birth_travel = moment(this.props.birth_travel, moment.ISO_8601).format("dddd  ที่ D MMMM Y")
    //console.log("birth_travel moment = " + birth_travel)

    return(
      <div className="astro-chart">
        <svg xmlns="http://www.w3.org/2000/svg" id="chart-svg">
          <ChartBase astros={this.state.astros} travel={this.state.travel} />
          <g ref={"canvas"} key={Date.now()} id="canvas">
            <AstroOrigin astros={this.state.astros} travel={this.state.travel} display={this.state.displayChart.origin} />
            <AstroTravel astros_travel={this.state.astros_travel} travel={this.state.travel} display={this.state.displayChart.travel} />
            <AstroSomput astros_somput={this.state.astros_somput} travel={this.state.travel} display={this.state.displayChart.somput} />
          </g>
        </svg>

        <div className="positions-rasi">
          { (this.state.displayChart.origin || this.state.displayChart.travel) ? (
              <div className="position-rasi position-origin" style={{opacity: 1}}>
              { Object.keys(data_origin).map((k) => {
                  return <RasiElement key={`rasi-origin-${k}`} rasi={k} values={data_origin[k]} rasiTravel={false} />
                })}
              </div>) : <div />
          }

          { this.state.travel ? ( 
              <div className="position-rasi position-travel" style={{opacity: 1}}>
              { Object.keys(data_travel).map((k) => {
                  return <RasiElement key={`rasi-travel-${k}`} rasi={k} values={data_travel[k]} rasiTravel={true} />
                })}
              </div>) : <div />
          }

          { this.state.displayChart.somput ? (
              <div className="position-rasi position-somput" style={{opacity: 1}}>
              { Object.keys(data_somput).map((k) => {
                  return <RasiElement key={`rasi-somput-${k}`} rasi={k} values={data_somput[k]} rasiTravel={false} />
              })}
              </div>) : <div /> 
          }
        </div>
        <div className="astro-travel">
          {/* <div className="toggle-travel">
            <input type="checkbox" name="travelCheckBox" onChange={(e) => this.toggleTravel(e)}/>ดวงจร
          </div>
          <SelectAstroType getAstroTravel={(d) => this.getAstroTravel(d)} toggleTravel={this.state.toggleTravel} /> */}

          { this.props.birth_travel != "" ?
            (<div className="travel-date">
              ดวงจรของวัน {`${birth_travel}`}</div>) : "" }
        </div>
      </div>
    )
  }
} //AstroChart

export default AstroChart
