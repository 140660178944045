import React from 'react'

class MoonPhase extends React.Component {
  constructor(props) {
    super(props)
  }
  
  render(){
    const { name, name_thai, newmoon, fullmoon, next_newmoon } = this.props.phase

    let t
    switch(name) {
      case 'new': t = newmoon; break;
      case 'full': t = fullmoon; break;
    }

    if(name == 'other') {
      return(<div className="moonphase"></div>)
    } else {
      return(
        <div className="moonphase">
          <div className="moonphase--wrap">
            <div className="moonphase--name">
              วันนี้เป็น <span>{ name_thai }</span>
              <span className={ `moonphase--icon moonphase--icon__${ name }moon` }></span>
            </div>
            <div className="moonphase--time">เกิด ณ เวลา <span>{ t }</span></div>
          </div>
        </div>
      )
    }
  }
}

export default MoonPhase
