import moment from 'moment'
import React from 'react'
import ReactDOM from 'react-dom'

import AstroToday from './AstroToday'
import './horotoday.scss'


class MoonPhase extends React.Component {
  constructor(props) {
    super(props)
  }
  
  render(){
    const { name, name_thai, newmoon, fullmoon, next_newmoon } = this.props.phase

    if(name == 'other') {
      return(<div className="horotoday--birth--item moonphase"></div>)

    } else {
      return(
        <div className="horotoday--birth--item moonphase">
          <div className="moonphase--wrap">
            <div className="moonphase--name">
              วันนี้เป็น <span>{ name_thai }</span>
              <span style={{ color: 'darkgoldenrod' }} className="moonphase--icon moonphase--icon__{ `${name}` }moon"></span>
            </div>
            <div className="moonphase--time">เกิด ณ เวลา <span>{ newmoon }</span></div>
          </div>
        </div>
      )
    }
  }
}

function DithiToday(props) {
  return (
    <div className="horotoday--dithi">
      { 
        (props.dithi != "") ? 
          <div className="horotoday--birth--item dithi-origin">
            <span className="dithi-label">ฤกษ์ยามวันนี้: </span><span className={ `dithi-value` }>{ props.dithi }</span>
          </div> : 
          <div></div> 
      }
    </div>
  )
}

function HoroTodayBirth(props) {
  return(
    <div className="horotoday--birth">
      <div className="horotoday--birth--item horotoday--birth__general">{ props.birth_description }</div>
      <div className="horotoday--birth--item horotoday--birth__thai">{ props.birth_thai }</div>
      <MoonPhase phase={props.phase} />
      <DithiToday dithi={props.dithi} />
    </div>
  )
}

class HoroToday extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  componentWillMount() {
  }

  componentWillUnMount() {
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.props.birth == nextProps.birth) { return false }
  }

  render() {
    //const lang = document.querySelector("[data-current-lang]").dataset.currentLang
    const lang = document.documentElement.lang
    let btnGetLukhana

    if(lang === 'th') {
      btnGetLukhana = <div className="horotoday--submit__wrap">
        <a className="btn btn--get-lukhana" href="/lukhana" data-turbolinks="false">
          <b>ผูกดวงลัคนา</b>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            width="28px" height="28px"
            viewBox="128 -179.1 459 512" xmlSpace="preserve">
            <path fill="#414e6d" d="M357.5-179.1c-121.9,0-220.7,98.8-220.7,220.7c0,77.7,40.3,146.1,101,185.4h4.9v8.8h229.5V227h4.9
              c60.8-39.3,101-107.6,101-185.4C578.2-80.3,479.4-179.1,357.5-179.1z"/>
            <path fill="#3f414c" d="M207.4,227h300.1v44.1H207.4L207.4,227z"/>
            <path fill="#abb4c6" d="M260.4-90.8c-2.9,0-5.6-1.4-7.3-3.8c-2.8-4-1.8-9.5,2.2-12.3c26.8-18.7,61.2-27.8,102.2-28.1
              c4.9,0,8.8,4,8.8,8.8c0,4.9-4,8.8-8.8,8.8h-0.4c-37.1,0-68,8.4-91.7,24.9C264-91.4,262.2-90.8,260.4-90.8L260.4-90.8z M189.8,50.4
              c-4.9,0-8.8-3.9-8.8-8.8c0-35.8,8.6-64.5,28-93.2c2.7-4,8.2-5.1,12.3-2.4c4,2.7,5.1,8.2,2.4,12.3c-17.3,25.6-25,51.3-25,83.3
              C198.6,46.5,194.7,50.4,189.8,50.4z"/>
            <g>
              <path fill="#FFFFFF" d="M472.3,27.6l-39-39l39-39l39,39L472.3,27.6z M458.3-11.4l14,14l14-14l-14-14L458.3-11.4z"/>
              <path fill="#FFFFFF" d="M472.3-29c-4.9,0-8.8-3.9-8.8-8.8c0,0,0,0,0,0v-17.7c0-4.9,3.9-8.8,8.8-8.8c0,0,0,0,0,0c4.9,0,8.8,3.9,8.8,8.8
                c0,0,0,0,0,0v17.7C481.1-33,477.2-29,472.3-29C472.3-29,472.3-29,472.3-29z M516.4-2.5h-17.7c-4.9,0-8.8-3.9-8.8-8.8c0,0,0,0,0,0
                c0-4.9,4-8.8,8.8-8.8h17.7c4.9,0,8.8,3.9,8.8,8.8C525.2-6.5,521.3-2.5,516.4-2.5C516.4-2.5,516.4-2.5,516.4-2.5L516.4-2.5z
                M472.3,41.6c-4.9,0-8.8-3.9-8.8-8.8c0,0,0,0,0,0V15.1c0-4.9,3.9-8.8,8.8-8.8c0,0,0,0,0,0c4.9,0,8.8,3.9,8.8,8.8c0,0,0,0,0,0v17.7
                C481.1,37.6,477.2,41.6,472.3,41.6C472.3,41.6,472.3,41.6,472.3,41.6z M445.8-2.5h-17.7c-4.9,0-8.8-3.9-8.8-8.8c0,0,0,0,0,0
                c0-4.9,4-8.8,8.8-8.8h17.7c4.9,0,8.8,3.9,8.8,8.8C454.6-6.5,450.7-2.5,445.8-2.5C445.8-2.5,445.8-2.5,445.8-2.5L445.8-2.5z
                M357.5,168.8l-39-39l39-39l39,39L357.5,168.8z M343.5,129.9l14,14l14-14l-14-14L343.5,129.9z"/>
              <path fill="#FFFFFF" d="M357.5,112.2c-4.9,0-8.8-3.9-8.8-8.8c0,0,0,0,0,0V85.7c0-4.9,3.9-8.8,8.8-8.8c0,0,0,0,0,0
                c4.9,0,8.8,3.9,8.8,8.8c0,0,0,0,0,0v17.7C366.3,108.3,362.4,112.2,357.5,112.2C357.5,112.2,357.5,112.2,357.5,112.2z M401.7,138.7
                H384c-4.9,0-8.8-3.9-8.8-8.8c0,0,0,0,0,0c0-4.9,3.9-8.8,8.8-8.8c0,0,0,0,0,0h17.7c4.9,0,8.8,3.9,8.8,8.8c0,0,0,0,0,0
                C410.5,134.7,406.5,138.7,401.7,138.7C401.7,138.7,401.7,138.7,401.7,138.7z M357.5,182.8c-4.9,0-8.8-3.9-8.8-8.8c0,0,0,0,0,0
                v-17.7c0-4.9,3.9-8.8,8.8-8.8c0,0,0,0,0,0c4.9,0,8.8,3.9,8.8,8.8c0,0,0,0,0,0V174C366.3,178.9,362.4,182.8,357.5,182.8
                C357.5,182.8,357.5,182.8,357.5,182.8z M331,138.7h-17.7c-4.9,0-8.8-3.9-8.8-8.8c0,0,0,0,0,0c0-4.9,3.9-8.8,8.8-8.8c0,0,0,0,0,0
                H331c4.9,0,8.8,3.9,8.8,8.8c0,0,0,0,0,0C339.9,134.7,335.9,138.7,331,138.7C331,138.7,331,138.7,331,138.7L331,138.7z"/>
            </g>
            <path fill="#1a2130" d="M551.7,306.4H163.3V280c0-9.8,7.9-17.8,17.8-17.8h353c9.8,0,17.7,7.9,17.7,17.7V306.4z"/>
            <path fill="#1a2130" d="M128,297.6h459v35.3H128V297.6z"/>
          </svg> 
        </a>
      </div>

    } else {
      btnGetLukhana = <div></div>
    }

    return (
      <div className="horotoday__wrap">
        <HoroTodayBirth birth_description={this.props.birth_description} birth_thai={this.props.birth_thai} phase={this.props.moonphase} dithi={this.props.dithi} />
        <AstroToday birth_travel={this.props.birth} astros={this.props.astros}/>

        <div className="horotoday--submit__wrap">
          { btnGetLukhana } 
        </div> 
      </div>
    )
  }
}

document.addEventListener('turbolinks:before-cache', function(e) {
  const horotoday = document.querySelector('#horotoday')
  if(horotoday != null) {
    ReactDOM.unmountComponentAtNode(horotoday)
  }
})

document.addEventListener('turbolinks:load', function(e){
  const horotoday = document.querySelector('#horotoday')

  if(horotoday != null) {
    const today = moment().format('YYYY-MM-DDTHH:mm')
    const url = `/horotoday/astro_travel?birth=${today}`

    let birth_description, birth_thai, astros
    //console.log(`will fetch horotoday data from ${url}`)

    fetch(url,
      {
        method: 'GET',
        headers: {
          //"X-User-Email": userEmail,
          //"X-User-Token": userToken
        }
      }
    )  
    .then(response => response.json())
    .then(horo => {
      let birth_desc = { ...horo.birth_description },
      birth_description = birth_desc.desc,
      birth_thai = birth_desc.thai_desc,
      astros = horo.astros,
      moonphase = horo.moonphase,
      dithi = horo.dithi.join(", ")

      ReactDOM.render(<HoroToday birth={today} astros={astros} birth_description={birth_description} birth_thai={birth_thai} moonphase={moonphase} dithi={dithi} />, horotoday)
    }) 
  }
})
