import './augury.scss'
import './augury_selection_bg.scss'

document.addEventListener('turbolinks:load', function(){
  // toggle augury content
  const auguryLists = document.querySelectorAll(".augury--list")
  if(auguryLists.length > 0) {
    auguryLists.forEach(btn => {
      btn.addEventListener('click', function(){
        toggleAuguryBody(btn)
      })
    })

    // explore augury body when clicking from notification page
    const auguryMonthId = window.location.hash
    if(auguryMonthId != ""){
      auguryLists.forEach(btn => btn.click())
    }
  }

  function toggleAuguryBody(auguryList){
    auguryList.querySelector("blockquote").classList.toggle("augury--item--group__hide")
    let exploreBtn = auguryList.querySelector(".augury--item--explore")
    exploreBtn.firstElementChild.classList.toggle("arrow-rotate") 
  }
})

