import React from 'react'
import ReactDOM from 'react-dom'
import FormProfile from './FormProfile.jsx'
import './profile.scss'


document.addEventListener('turbolinks:load', function(e){
  const form_profile = document.getElementById("form-profile")
  
  if(form_profile == null) { return }

  const profile_data = JSON.parse(form_profile.dataset.profile)
  ReactDOM.render(<FormProfile profile_data={ profile_data } />, form_profile)
})
